<template>
  <div class="new_market_body">
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="selectingProvider" />

    <!-- NEW UI -->

    <!-- HEADER AND SEARCH BAR -->
    <div class="newMarket_header">
        <!-- Header -->
        <div class="header_marketpalce_container" :class="loggedIn ? 'header_marketpalce_container_extra logged_in_bg_image':''">
            <div class="header_marketplace_title">
                Advanced Primary Care powered by Apaly
            </div>
            <div class="header_marketplace_sub"> 
                Find your local APC provider today 
            </div>
            <div class="header_marketpalce_inputs">
                <div class="header_marketplace_search_cont_2 header_employer_select mr-1">
                    <v-select
                        id="employerSelect"
                        ref="employerSelect"
                        v-model="selectedEmployerInfo"
                        :items="employers"
                        placeholder="Select your employer"
                        @change="selectedEmployer"
                        @click:clear="clearSearchEmployer"
                        @click="focusSearchInput()"
                        item-value="id"
                        return-object
                        :rules="requiredRules"
                        class="header_marketplace_fields"
                        no-data-text="No employer found"
                        v-click-outside="onVSelectClickOutside"
                        solo
                        flat
                        dense
                        clearable
                        required
                        :disabled="hasEmployer && loggedIn"
                        hide-details
                    >

                        <template v-slot:prepend-item>
                            <div class="custom_select_input_container">
                                <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search employer here" @input="searchingEmployers"/>
                            </div>
                        </template>

                        <!-- Show Selected Employer -->
                        <template #selection="{ item }">
                            {{ item.companyName }}
                        </template>

                        <!-- List of Employers -->
                        <template #item="{ item }">
                            <div>{{ item.companyName }}</div>
                            <!-- <div :class="{ 'active-item': 'active' }">{{ item.companyName }} </div> -->
                        </template>

                        <template #item-disabled="{ item }">
                            <div>{{ item.companyName }}</div>
                        </template>
                    </v-select>
                </div>

                <div class="header_marketplace_search_cont_2" v-if="!isMobile">
                    <v-text-field 
                        prepend-inner-icon="mdi-map-marker"
                        placeholder="Search by address" 
                        class="header_marketplace_fields"
                        v-model="address"
                        id="pac-input" 
                        ref="pac-input" 
                        solo
                        flat
                        dense
                    >
                    </v-text-field>

                    <!-- <img src="/svg/icon_map_check.svg">
                    <input 
                        :disabled="!selectedEmployerInfo" 
                        v-model="address" 
                        type="text" 
                        id="pac-input" 
                        class="header_marketplace_search"
                        placeholder="Search by address"
                    > -->
                </div>
                <button @click="searchAddress" class="header_icon_btn" v-if="!isMobile">
                    <img src="/svg/icon_search.svg">
                </button>
                <button @click.stop="openDrawer()" class="filter_button" v-if="!isMobile">
                    <span v-if="!isMobile">Filter</span>
                    <img class="ml-1" style="height: 18px;" src="/svg/sortAndFilterWhite.svg" />
                </button>
            </div>
            <div class="header_marketpalce_inputs_2" v-if="isMobile">
                <div class="header_marketplace_search_cont_2">
                    <v-text-field 
                        prepend-inner-icon="mdi-map-marker"
                        placeholder="Search by address" 
                        class="header_marketplace_fields"
                        v-model="address"
                        id="pac-input" 
                        ref="pac-input" 
                        solo
                        flat
                        dense
                    >
                    </v-text-field>
                </div>
                <button @click="searchAddress" class="header_icon_btn">
                    <img src="/svg/icon_search.svg">
                </button>
                <button @click.stop="openDrawer()" class="filter_button">
                    <span v-if="!isMobile">Filter</span>
                    <img class="ml-1" style="height: 18px;"src="/svg/sortAndFilterWhite.svg" />
                </button>
            </div>
        </div>

        <!-- Loader -->
        <div v-if="loader">
        <v-progress-linear
            class="text-center justify-center mt-0 mb-2"
            color="primary"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>
        </div>
    </div>
    <div class="newMarket_container" >
      <div class="newMarket_col" v-show="!marketplaceViewMapMobile">
        <div class="newMarket_cards">
          <!-- ALL SOLUTIONS -->
          <div
            v-if="solutions == 'all_solutions'"
            class="col-12"
            style="height: calc(100vh - 70px); overflow-y: auto; overflow-x: hidden"
          >
            <!-- FILTER BUTTONS -->
            <div class="col-12 row pb-0 mb-0 d-flex pr-0 filter_tab_container">
              <div class="d-flex filter_tab_container_item">
                <!-- ACTIVE EMPLOYER -->
                <div
                  v-if="employer && employer.status == 'active'"
                  class="col-12 row mt-2 d-flex"
                  style="border: 1px solid #cccccc; border-radius: 8px"
                >
                  <img src="/svg/firework.svg" class="mr-2" />
                  <p>
                    <strong class="mr-1"> {{ employer.companyName }} </strong>
                    is participating with Apaly and will pay up to 100% of your
                    benefit
                  </p>
                </div>
                <!-- INACTIVE EMPLOYER -->
                <div
                  v-if="employer && employer.status != 'active'"
                  class="col-12 d-flex"
                  style="border: 1px solid #cccccc; border-radius: 8px"
                >
                  <img src="/svg/firework.svg" class="mr-2" />
                  <p>
                    <strong class="mr-1"> {{ employer.companyName }} </strong>
                    is not yet participating with Apaly. A representative will
                    contact the company to bring you this benefit
                  </p>
                </div>
                <!-- FILTER BUTTON -->
                <div class="col-12 marketplace_tabs">
                  <div class="d-flex">
                    <div
                      :class="`tab-style ${tab == 'clinics' ? 'active-tab-style' : ''}`"
                      @click="changeTab('clinics')"
                      role="button"
                    >
                      Clinics
                    </div>
                    <div class="tab_divider"></div>
                    <div
                      :class="`tab-style ${tab == 'clinicians' ? 'active-tab-style' : ''}`"
                      @click="changeTab('clinicians')"
                      role="button"
                    >
                      Providers
                    </div>
                    <div class="tab_divider"></div>
                    <div
                      :class="`tab-style ${tab == 'all' ? 'active-tab-style' : ''}`"
                      @click="changeTab('all')"
                      role="button"
                    >
                      All
                    </div>
                  </div>
                  <div class="marketplace_view_switch">
                    <button @click.stop="marketplaceViewMapMobile = !marketplaceViewMapMobile" class="marketplace_view_switch_button">
                        View Map
                    </button>
                    <!-- <button class="newMarket_showMap_mobile" @click="showMap = !showMap">
                      {{ showMap == true ? 'Show' : 'Hide'}} Map
                    </button> -->
                  </div>
                </div>
              </div>

              <!-- FILTER CHIPS -->
              <span class="filter_tab_container_item" v-if="didFilter">
                <v-sheet max-width="79%">
                  <v-slide-group show-arrows>

                    <!-- Clear All -->
                    <v-slide-item v-if="didFilter" >
                      <v-chip class="mr-1" @click="clearSearch('clearFilter')">
                        Clear All
                      </v-chip>
                    </v-slide-item>

                    <!-- Searched Name -->
                    <v-slide-item v-if="searchByName">
                      <v-chip class="mr-1">
                        {{ searchByName }}
                        <v-icon right @click="removeSearchedName()"> close </v-icon>
                      </v-chip>
                    </v-slide-item>

                    <!-- Accpting New Patient Chip -->
                    <v-slide-item v-if="newPatients">
                      <v-chip class="mr-1">
                        Accepting New Patients
                        <v-icon right @click="removeNewPatients()"> close </v-icon>
                      </v-chip>
                    </v-slide-item>

                    <!-- Pedia Available Chip -->
                    <v-slide-item v-if="pediatrics">
                      <v-chip class="mr-1">
                        Pediatrics available
                        <v-icon right @click="removePediatricService()"> close </v-icon>
                      </v-chip>
                    </v-slide-item>

                    <!-- Credentials Chips -->
                    <v-slide-item v-for="( crendential, credentialIndex ) in selectedCredentials" :key="'crendential-' + credentialIndex">
                      <v-chip class="mr-1">
                        {{ crendential }}
                        <v-icon right @click="removeChip(crendential, selectedCredentials)"> close </v-icon>
                      </v-chip>
                    </v-slide-item>

                    <!-- Specialties Chips -->
                    <v-slide-item v-for="(specialties, sIndex) in selectedSpecialities" :key="'specialties-' + sIndex">
                      <v-chip class="mr-1">
                        {{ specialties }}
                        <v-icon right @click="removeChip(specialties, selectedSpecialities)"> close </v-icon>
                      </v-chip>
                    </v-slide-item>

                    <!-- Gender Chips -->
                    <v-slide-item v-for="(gender, genderIndex) in selectedGenders" :key="'gender-' + genderIndex">
                      <v-chip class="mr-1" >
                        {{ gender == "F" ? "Female" : "Male" }}
                        <v-icon right @click="removeChip(gender, selectedGenders)"> close </v-icon>
                      </v-chip>
                    </v-slide-item>

                    <!-- Company URL Chips -->
                    <v-slide-item v-for="(group, groupIndex) in selectedGroupAndOrganizations" :key="'group-' + groupIndex">
                      <!-- REMOVABLE CHIP -->
                      <v-chip v-if="!isCustomURL" class="mr-1">
                        {{ group }}
                        <v-icon right @click="removeChip(group, selectedGroupAndOrganizations)"> close </v-icon>
                      </v-chip>

                      <!-- STAY UNLESS UNCHECKED IN THE FILTER -->
                      <v-chip v-else class="mr-1">
                        {{ group }}
                      </v-chip>

                    </v-slide-item>

                    <!-- Close and Clear All buttons -->
                    <v-slide-item v-if="urlCompany">
                      <v-chip class="mr-1">
                        {{ urlCompany }}
                        <v-icon right small  @click="removeCompanyFromUrl(chip)"> close </v-icon>
                      </v-chip>
                    </v-slide-item>
                    
                    <v-slide-item v-if="urlCompany">
                      <span
                        role="button"
                        style="
                          color: #0069f3;
                          display: flex;
                          align-items: center;
                          margin-left: 20px;
                        "
                        @click="clearSearch()"
                        >Clear all</span
                      >
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet>
              </span>

            </div>

            <!-- SEARCH RESULTS CONTAINER -->
            <div class="" v-show="!marketplaceViewMapMobile || !isMobile">
              <!-- COMPONENT: All Market Place Items -->
              <AllMarketplaceItems
                v-if="slicedMarketplace.length > 0"
                ref="allMarketplaceItemsRef"
                :marketplace="slicedMarketplace"
                :tabTitle="tabTitle"
                :tab="tab"
                :searchQuery="searchQuery"
                :zipOrState="zipOrState"
                :showMap="showMap"
                @centerMap="centerPin"
                @sendUserInfo="getUserInfo"
              />

              <!-- ALERT: No results for this search -->
              <!-- <div v-else-if="slicedMarketplace.length <= 0">
                <v-alert
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  class="text-gray mt-2"
                  @click="noResultsFoundReset()"
                >
                  No results found for this search!
                  <br />
                  Please click
                  <span
                    role="button"
                    style="color:#1976D2;"
                    @click="noResultsFoundReset()"
                    >here </span
                  >to reset filters
                </v-alert>
              </div> -->

              <!-- ALERT: are We finding clinics near you -->
              <div v-else-if="loader">
                <v-alert
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  class="text-gray mt-2"
                >
                  We are finding clinics near you
                </v-alert>
              
              </div>
              
              <!-- ALERT: No clinicans found near your location -->
              <div v-else>
                <v-alert
                  v-if="tab == 'clinicians'"
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  class="text-gray mt-2"
                >
                  No clinicians found near your location
                </v-alert>

                <!-- ALERT: No clinics found near your location -->
                <!-- <v-alert
                  v-else
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  class="text-gray mt-2"
                >
                  No clinics found near your location!
                </v-alert> -->

                <div class="marketplace_notice" v-else>
                  <div class="marketplace_notice_title">There were no clinics found near the location you entered.</div>
                  <div class="marketplace_notice_text">
                    You can try widening your search area, or enter your email and we’ll help you with a virtual advanced primary care provider (you’ll be notified as soon as a clinic near you becomes available).
                  </div>
                  <div class="marketplace_notice_row">
                    <input v-if="!loggedIn" @input="delayCheckmail()" v-model="unregisteredEmail" placeholder="Enter email to be notified" dense type="email" class="marketpalce_notice_input" />
                    <button class="marketplace_notice_button dialog_btn_primary" :disabled="!validEmail && !loggedIn" @click="saveEmailAndNotifyNearClinic()">
                      Notify me
                    </button>
                  </div>
                </div>

              </div>

              <!-- Show if only 3 or less cards are being given by BE after search -->
              <div class="marketplace_notice" v-if="totalPages > 0 && totalPages < 4">
                <div class="marketplace_notice_title">Find more providers</div>
                <div class="marketplace_notice_text">
                  Try adjusting your search filters and address for more service options!
                </div>
              </div>

              <!-- Added by T.N. using vuetify pagination -->
              <div class="text-center" v-show="totalPages > 1">
                <v-pagination
                  v-model="pageNumber"
                  :length="totalPages"
                  :disabled="loader"
                  @input="setPage"
                  :total-visible="4"
                ></v-pagination>
              </div>

              <!-- <div v-if="!loader && !noResultsFromSearch && hasNextPage  && marketplace.length > 0" class="row col-12 d-flex justify-center pl-14 pr-16">
              <v-btn
                size="small"
                color="#194680"
                style="font-size: 12px; color: white; height: 20px; min-width: 10px;"
                class="mr-2 mt-2"
                :disabled="pageNumber === 1"
                @click="prevPage()"
              >
                <v-icon size="x-small">mdi-arrow-left</v-icon>
              </v-btn>
              <span class="d-flex">
                <v-chip
                  v-for="(page, index) in visiblePages"
                  :key="index"
                  :color="getChipColor(index)"
                  :style="{ color: getTextColor(index) }"
                  @click="goToPage(page)"
                  class="mr-1"
                >
                  {{ page }}
                </v-chip>
                <p v-if="totalPages > pagesToShow && pageNumber != totalPages && (totalPages - 2) > pageNumber  "> <strong>...</strong></p>
              </span>
              <v-btn
                size="small"
                color="#194680"
                style="font-size: 12px; color: white; height: 20px; min-width: 10px;"
                class="ml-2 mt-2"
                :disabled="pageNumber === totalPages"
                @click="nextPage()"
              >
                <v-icon size="x-small">mdi-arrow-right</v-icon>
              </v-btn>
            </div> -->
            </div>
          </div>

          <!-- NOT ALL SOLUTIONS -->
          <div v-else>
            <MySolutions />
          </div>
        </div>
      </div>
      <div class="newMarket_col" v-show="marketplaceViewMapMobile  || !isMobile">
        <!-- MAP -->
        <div class="newMarket_map">
            <div class="marketplace_view_switch switch_to_list" v-show="marketplaceViewMapMobile">
                <button @click.stop="marketplaceViewMapMobile = !marketplaceViewMapMobile" class="marketplace_view_switch_button_map">
                    View List
                </button>
            </div>
          <GmapMap
            :center="center"
            :zoom="12"
            clickableIcons: false
            style="width: 100%"
            :class="`map-height ${showMap == true ? '' : 'showMap'}`"
            ref="gmap"
            :options="mapOptions"
            @bounds_changed="onBoundsChanged"
          >
            <GmapMarker :position="userCenter" @click="openUserCard(userCenter)" />

            <GmapMarker
              v-for="(place, index) in places"
              :key="index"
              :position="place.coordinates"
              @click="openCard(place)"
              :icon="{ 
                url: place.newPatients ? `/dark-blue.png` : `${newPatients ? '/imageThatDoesntExist.png':'/dark-gray.png'}`, 
                scaledSize: { width: 40, height: 60 }
              }"
            />

            <!-- imageThatDoesntExist.png is added, this image does not exist but is put there to avoid rendering error in map markers -->

            <GmapInfoWindow
              ref="infoPosition"
              :position="infoWindowPosition"
              :opened="infoWindowOpened"
              @closeclick="closeInfoWindow"
              :options="infoOptions"
              class="GmapInfoWindow"
            >
              <!-- <div class="no_box_shadow " style="margin-right: 10px; margin-bottom: 10px; box-shadow: none;"> -->
              <div class="card_layout_map" :style="resolveMarketplaceBackgroundColor(card2)" >
                <!-- CARD -->
                <v-card
                  v-if="card2"
                  class="card-radius v_card_height no_box_shadow map_pin_window"
                >
                  <!-- <div class="marketplace-item tester"> -->
                  <div class="no_box_shadow">
                    <!-- CARD HEADER -->
                    <div class="market_card_header_map">
                      <!-- LOGO -->
                      <div
                        class="card_logo_map"
                        :style="`background-image:url(${returnCardImage(card2)})`"
                      ></div>

                      &emsp;

                      <!-- DPC Name -->
                      <!-- <div class="marketplace-item-content-name" :class="!loggedIn ? 'pt-8' : 'pt-4'"> -->
                      <div class="marketplace-item-content-name card_title_map">
                        {{ returnClinicanName(card2.name) }}
                        <span v-if="card2.credentials"
                          >, {{ card2.credentials }}</span
                        >
                      </div>
                    </div>

                    <div class="card_info_container_map">
                      <div class="card_info_icontexts_map">
                        <!-- ADDRESS -->
                        <div v-if="card2.entity == 'location'" class="marketplace-item-content-address pt-2 card_icon_text">
                          <img
                            class="mr-2 card_icon_map"
                            src="/svg/cardLocation.svg"
                          />
                          <p class="ma-0 card_text_map">
                            {{ card2.address }}&nbsp;{{ card2.city }},&nbsp;{{
                              card2.state
                            }}&nbsp;{{ card2.zipCode }}
                          </p>
                        </div>

                        <!-- DISTANCE CHIP -->
                        <!-- <div  class="distance_chip ml-8">
                          <img class="ml-1 mr-2 card_icon" src="/svg/car.svg"/>
                          &nbsp;{{ distance_format(card.distance) }} miles from you
                        </div> -->

                        <!-- CHIP" Covered/Uncovered -->
                        <!-- <div v-if="card.covered" class="distance_chip ml-8">
                          {{  card.covered ? 'Covered' : 'Uncovered' }}&nbsp;
                          <img class="ml-1 mr-2 card_icon" :src="`/svg/${card.covered ? 'coveredIcon.svg' : 'uncoveredIcon.svg'}`"/>
                        </div> -->

                        <div v-show="card2.entity == 'location'">
                          <!-- APPOINTMENT -->
                          <div class="marketplace-item-content-category pt-2 card_icon_text" v-if="card2.newPatients">
                            <img class="mr-2 card_icon_map" src="/svg/calendar_alt.svg"/>
                            <p class="ma-0 card_text card_text_map">
                              Next day appointments typically available
                            </p>
                          </div>

                          <!-- NOT ACCEPTING NEW PATIENTS -->
                          <div class="marketplace-item-content-category pt-2 card_icon_text" v-else-if="!card2.newPatients">
                            <img class="mr-2 card_icon_map" src="/svg/info_circle.svg"/>
                            <p class="ma-0 card_text card_text_map">
                              Not accepting new patients
                            </p>
                          </div>

                          <!-- PROFILE EXTRA INFO -->
                          <div class="marketplace-item-content-category pt-2 card_icon_text">
                            <img class="mr-2 card_icon_map" src="/svg/map_marker_smile.svg"/>

                            <!-- SEE IF card.name HAS "NICE" -->
                            <p class="ma-0 card_text card_text_map">
                              {{ card2.homeCare == true ? "In Home Care;" : "At-Clinic;" }}
                              Virtual; Text/Chat; Phone; At Work
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- BUTTONS -->
                      <div class="card_info_buttons_map" v-if="!card2.isVirtualLocation">

                        <!-- Show Membership Status -->
                        <!-- <button class="card_buttons card_button_disabled map_window_btn" v-if="memberInfo?.subscriberRelation == 'Self' && card2?.entity == 'location' && memberInfo?.providerId == card2?.id && memberInfo?.locationId == card2?.locationId && memberInfo?.setup != 'incomplete'" disabled>
                          {{ userInfo?.subscription?.status == "active" ? "Active" : "Pending Membership" }}
                        </button> -->
                   
                        <!-- Confirming Eligibility -->
                        <button class="card_buttons" v-if="currentMemberInfo?.nextStep == 'confirmPlan' && card2?.entity == 'location' && card2?.newPatients && currentMemberInfo?.locationId == card2?.locationId"  @click="goToRegister(card2.id, card2, card2.entity)" style="white-space:nowrap;">
                            Confirm eligibility
                        </button>

                        <!-- Pending membership -->
                        <button class="card_buttons" v-else-if="card2?.locationId == currentMemberInfo?.location?.id && currentMemberInfo.subscription?.status === 'pending'"  @click="goToDetails(card2, card2.entity)">
                            Pending Membership
                        </button>

                        <!-- Active membership -->
                        <button class="card_buttons" v-else-if="card2?.locationId == currentMemberInfo?.location?.id && currentMemberInfo.subscription?.status === 'active'"  @click="goToDetails(card2, card2.entity)">
                            Active Membership
                        </button>

                        <!-- Select Button -->
                        <button class="card_buttons" v-else-if="card2?.entity == 'location' && card2?.newPatients"  @click="selectLocation(card2.id, card2, card2.entity)">
                            Select
                        </button>

                        <!-- Join Waitlist -->
                        <div class="card_button_joinwaitlist map_window_btn" v-else-if="!card2.newPatients" @click="joinWaitlist(card2.locationId)">
                            Join the waitlist 
                        </div>
                                        
                        <!-- View Details -->
                        <button class="map_btn card_buttons map_window_btn" @click="goToDetails(card2, card2.entity)">
                          View Details
                        </button>
                      </div>
                      <div class="card_info_buttons_map" v-else>
                        <!-- View Details -->
                        <button class="map_btn card_buttons map_window_btn" @click="goToDetails(card2, card2.entity)">
                            View Details
                        </button>
                        </div>
                    </div>
                  </div>
                </v-card>

                <div
                  v-else-if="cardLoaded"
                  style="width: 100%; text-align: center"
                >
                  <v-progress-circular
                    indeterminate
                    :size="50"
                    color="primary"
                    class="text-center mt-5 mb-5"
                  ></v-progress-circular>
                </div>

                <v-card v-else>
                  <div>You are here</div>
                </v-card>
              </div>
            </GmapInfoWindow>
          </GmapMap>
        </div>
      </div>
    </div>

    <!-- OLD UI -->
    <div style="display: none">
      <!-- Header -->
      <!-- <div class="header_marketpalce_container" :class="loggedIn ? 'header_marketpalce_container_extra':''">
        <div class="header_marketplace_title">
          Welcome to Advanced Primary Care - Powered by Apaly
        </div>
        <div class="header_marketplace_sub">
          Meet your personal provider today
        </div>
        <div class="header_marketpalce_inputs">
          <div class="header_marketplace_search_cont">
            <img src="/svg/icon_map_check.svg">
            <input v-model="address" type="text" id="pac-input" class="header_marketplace_search" placeholder="Search by address">
          </div>
          <button @click="searchAddress" class="header_icon_btn">
            <img src="/svg/icon_search.svg">
          </button>
        </div>
      </div> -->

      <!-- Loader -->
      <!-- <div v-if="loader">
        <v-progress-linear
          class="text-center justify-center mt-0 mb-2"
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </div> -->

      <!-- Contents -->
      <!-- <div class="d-flex row ma-0 map-position" style="width: 100% !important;"> -->
        <!-- ALL SOLUTIONS -->
        <!-- <div
          v-if="solutions == 'all_solutions'"
          class="col-md-8 col-12 mt-1 tester"
          style="height: 70vh; overflow-y: auto; overflow-x: hidden"
        > -->
          <!-- FILTER BUTTONS -->
          <!-- <div class="col-12 row pb-0 mb-0 d-flex pr-0 filter_tab_container">
            <div class="d-flex filter_tab_container_item"> -->
              <!-- ACTIVE EMPLOYER -->
              <!-- <div
                v-if="employer && employer.status == 'active'"
                class="col-12 row mt-2 d-flex"
                style="border: 1px solid #cccccc; border-radius: 8px"
              >
                <img src="/svg/firework.svg" class="mr-2" />
                <p>
                  <strong class="mr-1"> {{ employer.companyName }} </strong>
                  is participating with Apaly and will pay up to 100% of your
                  benefit
                </p>
              </div> -->
              <!-- INACTIVE EMPLOYER -->
              <!-- <div
                v-if="employer && employer.status != 'active'"
                class="col-12 d-flex"
                style="border: 1px solid #cccccc; border-radius: 8px"
              >
                <img src="/svg/firework.svg" class="mr-2" />
                <p>
                  <strong class="mr-1"> {{ employer.companyName }} </strong>
                  is not yet participating with Apaly. A representative will
                  contact the company to bring you this benefit
                </p>
              </div> -->
              <!-- FILTER BUTTON -->
              <!-- <div
                class="col-12 pb-0 marketplace_tabs"
              >
                <div class="d-flex">
                  
                  
                  <div
                    :class="`tab-style ${tab == 'clinics' ? 'active-tab-style' : ''}`"
                    @click="changeTab('clinics')"
                    role="button"
                  >
                    Clinics
                  </div>
                  <div class="tab_divider"></div>
                  <div
                    :class="`tab-style ${tab == 'clinicians' ? 'active-tab-style' : ''}`"
                    @click="changeTab('clinicians')"
                    role="button"
                  >
                    Providers
                  </div>
                  <div class="tab_divider"></div>
                  <div
                    :class="`tab-style ${tab == 'all' ? 'active-tab-style' : ''}`"
                    @click="changeTab('all')"
                    role="button"
                  >
                    All
                  </div>
                </div>
                <div class="tab_filter">
                  <v-btn
                    class=" "
                    @click.stop="openDrawer()"
                    color="red"
                    elevation="3"
                    outlined
                    style="border-radius: 8px"
                  >
                    Filter
                    <img class="ml-1" src="/svg/sortAndFilter.svg" />
                  </v-btn>
                </div>
                
                
                
              </div>
            </div> -->

            <!-- FILTER CHIPS -->
            <!-- <span class="filter_tab_container_item" v-if="didFilter">
              <v-sheet max-width="79%"> -->
                <!-- <v-slide-group show-arrows> -->
                  <!-- Searched Name -->
                  <!-- <v-slide-item v-if="searchByName">
                    <v-chip class="mr-1">
                      {{ searchByName }}
                      <v-icon right @click="removeSearchedName()"> close </v-icon>
                    </v-chip>
                  </v-slide-item> -->

                  <!-- Accpting New Patient Chip -->
                  <!-- <v-slide-item v-if="newPatients">
                    <v-chip class="mr-1">
                      Accepting New Patients
                      <v-icon right @click="removeNewPatients()"> close </v-icon>
                    </v-chip>
                  </v-slide-item> -->

                  <!-- Pedia Available Chip -->
                  <!-- <v-slide-item v-if="pediatrics">
                    <v-chip class="mr-1">
                      Pediatrics available
                      <v-icon right @click="removePediatricService()"> close </v-icon>
                    </v-chip>
                  </v-slide-item> -->

                  <!-- Credentials Chips -->
                  <!-- <v-slide-item v-for="( crendential, credentialIndex ) in selectedCredentials" :key="'crendential-' + credentialIndex">
                    <v-chip class="mr-1">
                      {{ crendential }}
                      <v-icon right @click="removeChip(crendential, selectedCredentials)"> close </v-icon>
                    </v-chip>
                  </v-slide-item> -->

                  <!-- Specialties Chips -->
                  <!-- <v-slide-item v-for="(specialties, sIndex) in selectedSpecialities" :key="'specialties-' + sIndex">
                    <v-chip class="mr-1">
                      {{ specialties }}
                      <v-icon right @click="removeChip(specialties, selectedSpecialities)"> close </v-icon>
                    </v-chip>
                  </v-slide-item> -->

                  <!-- Gender Chips -->
                  <!-- <v-slide-item v-for="(gender, genderIndex) in selectedGenders" :key="'gender-' + genderIndex">
                    <v-chip class="mr-1" >
                      {{ gender == "F" ? "Female" : "Male" }}
                      <v-icon right @click="removeChip(gender, selectedGenders)"> close </v-icon>
                    </v-chip>
                  </v-slide-item> -->

                  <!-- Company URL Chips -->
                  <!-- <v-slide-item v-for="(group, groupIndex) in selectedGroupAndOrganizations" :key="'group-' + groupIndex"> -->
                    <!-- REMOVABLE CHIP -->
                    <!-- <v-chip v-if="!isCustomURL" class="mr-1">
                      {{ group }}
                      <v-icon right @click="removeChip(group, selectedGroupAndOrganizations)"> close </v-icon>
                    </v-chip> -->

                    <!-- STAY UNLESS UNCHECKED IN THE FILTER -->
                    <!-- <v-chip v-else class="mr-1">
                      {{ group }}
                    </v-chip> -->

                  <!-- </v-slide-item> -->

                  <!-- Close and Clear All buttons -->
                  <!-- <v-slide-item v-if="urlCompany">
                    <v-chip class="mr-1">
                      {{ urlCompany }}
                      <v-icon right small  @click="removeCompanyFromUrl(chip)"> close </v-icon>
                    </v-chip>
                  </v-slide-item>
                  
                  <v-slide-item v-if="urlCompany">
                    <span
                      role="button"
                      style="
                        color: #0069f3;
                        display: flex;
                        align-items: center;
                        margin-left: 20px;
                      "
                      @click="clearSearch()"
                      >Clear all</span
                    >
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </span>

          </div> -->

          <!-- SEARCH RESULTS CONTAINER -->
          <!-- <div class=""> -->
            <!-- COMPONENT: All Market Place Items -->
            <!-- <AllMarketplaceItems
              v-if="!noResultsFromSearch && slicedMarketplace.length > 0"
              ref="allMarketplaceItemsRef"
              :marketplace="slicedMarketplace"
              :tabTitle="tabTitle"
              :tab="tab"
              :searchQuery="searchQuery"
              :zipOrState="zipOrState"
              :showMap="showMap"
              @centerMap="centerPin"
              @sendUserInfo="getUserInfo"
            /> -->

            <!-- ALERT: No results for this search -->
            <!-- <div v-else-if="noResultsFromSearch">
              <v-alert
                border="bottom"
                colored-border
                color="primary"
                elevation="2"
                class="text-gray mt-2"
                @click="noResultsFoundReset()"
              >
                No results found for this search!
                <br />
                Please click
                <span
                  role="button"
                  style="color:#1976D2;text-underline"
                  @click="noResultsFoundReset()"
                  >here </span
                >to reset filters
              </v-alert>
            </div> -->

            <!-- ALERT: are We finding clinics near you -->
            <!-- <div v-else-if="loader">
              <v-alert
                border="bottom"
                colored-border
                color="primary"
                elevation="2"
                class="text-gray mt-2"
              >
                We are finding clinics near you
              </v-alert>
             
            </div> -->
            
            <!-- ALERT: No clinicans found near your location -->
            <!-- <div v-else>
              <v-alert
                v-if="tab == 'clinicians'"
                border="bottom"
                colored-border
                color="primary"
                elevation="2"
                class="text-gray mt-2"
              >
                No clinicians found near your location
              </v-alert>

              <div class="marketplace_notice" v-else>
                <div class="marketplace_notice_title">No clinics found near your location</div>
                <div class="marketplace_notice_text">
                  Try adjusting your search filters and address for more service options.
                  <br> Or enter your email and we'll notify you when there is new locations available near you.
                </div>
                <div class="marketplace_notice_row">
                  <input v-if="!loggedIn" @input="delayCheckmail()" v-model="unregisteredEmail" placeholder="Enter email to be notified" dense type="email" class="marketpalce_notice_input" />
                  <button class="marketplace_notice_button dialog_btn_primary" :disabled="!validEmail && !loggedIn" @click="saveEmailAndNotifyNearClinic()">
                    Notify me
                  </button>
                </div>
              </div>

            </div> -->

            <!-- Show if only 3 or less cards are being given by BE after search -->
            <!-- <div class="marketplace_notice" v-if="totalPages > 0 && totalPages < 4">
              <div class="marketplace_notice_title">Find more providers</div>
              <div class="marketplace_notice_text">
                Try adjusting your search filters and address for more service options!
              </div>
            </div> -->

            <!-- Added by T.N. using vuetify pagination -->
            <!-- <div class="text-center" v-show="totalPages > 1">
              <v-pagination
                v-model="pageNumber"
                :length="totalPages"
                :disabled="loader"
                @input="setPage"
                :total-visible="4"
              ></v-pagination>
            </div> -->

            <!-- <div v-if="!loader && !noResultsFromSearch && hasNextPage  && marketplace.length > 0" class="row col-12 d-flex justify-center pl-14 pr-16">
            <v-btn
              size="small"
              color="#194680"
              style="font-size: 12px; color: white; height: 20px; min-width: 10px;"
              class="mr-2 mt-2"
              :disabled="pageNumber === 1"
              @click="prevPage()"
            >
              <v-icon size="x-small">mdi-arrow-left</v-icon>
            </v-btn>
            <span class="d-flex">
              <v-chip
                v-for="(page, index) in visiblePages"
                :key="index"
                :color="getChipColor(index)"
                :style="{ color: getTextColor(index) }"
                @click="goToPage(page)"
                class="mr-1"
              >
                {{ page }}
              </v-chip>
              <p v-if="totalPages > pagesToShow && pageNumber != totalPages && (totalPages - 2) > pageNumber  "> <strong>...</strong></p>
            </span>
            <v-btn
              size="small"
              color="#194680"
              style="font-size: 12px; color: white; height: 20px; min-width: 10px;"
              class="ml-2 mt-2"
              :disabled="pageNumber === totalPages"
              @click="nextPage()"
            >
              <v-icon size="x-small">mdi-arrow-right</v-icon>
            </v-btn>
          </div> -->
          <!-- </div>
        </div> -->

        <!-- NOT ALL SOLUTIONS -->
        <!-- <div v-else>
          <MySolutions />
        </div> -->

        <!-- MAP -->
        <!-- <div class="col-md-4 col-12 map_mobile">
          <GmapMap
            :center="center"
            :zoom="12"
            clickableIcons: false
            style="width: 100%; display: none;"
            class="map-height"
            ref="gmap"
            :options="mapOptions"
          >
            <GmapMarker :position="userCenter" @click="openUserCard(userCenter)" />

            <GmapMarker
              v-for="(place, index) in places"
              :key="index"
              :position="place.coordinates"
              @click="openCard(place)"
              :icon="{ 
                url: place.newPatients ? `/dark-blue.png` : `${newPatients ? '/imageThatDoesntExist.png':'/dark-gray.png'}`, 
                scaledSize: { width: 40, height: 60 }
              }"
            /> -->

            <!-- imageThatDoesntExist.png is added, this image does not exist but is put there to avoid rendering error in map markers -->

            <!-- <GmapInfoWindow
              ref="infoPosition"
              :position="infoWindowPosition"
              :opened="infoWindowOpened"
              @closeclick="closeInfoWindow"
              :options="infoOptions"
            > -->
              <!-- <div class="no_box_shadow " style="margin-right: 10px; margin-bottom: 10px; box-shadow: none;"> -->
              <!-- <div class="card_layout_map" :style="resolveMarketplaceBackgroundColor(card2)" > -->
                <!-- CARD -->
                <!-- <v-card
                  v-if="card2"
                  height="auto"
                  style="width: calc(100% - 15px)"
                  class="card-radius v_card_height no_box_shadow map_pin_window"
                > -->
                  <!-- <div class="marketplace-item tester"> -->
                  <!-- <div class="no_box_shadow"> -->
                    <!-- CARD HEADER -->
                    <!-- <div class="market_card_header_map"> -->
                      <!-- LOGO -->
                      <!-- <div
                        class="card_logo_map"
                        :style="`background-image:url(${returnCardImage(card2)})`"
                      ></div>

                      &emsp; -->

                      <!-- DPC Name -->
                      <!-- <div class="marketplace-item-content-name" :class="!loggedIn ? 'pt-8' : 'pt-4'"> -->
                      <!-- <div class="marketplace-item-content-name card_title_map">
                        {{ returnClinicanName(card2.name) }}
                        <span v-if="card2.credentials"
                          >, {{ card2.credentials }}</span
                        >
                      </div>
                    </div>

                    <div class="card_info_container_map">
                      <div class="card_info_icontexts_map"> -->
                        <!-- ADDRESS -->
                        <!-- <div v-if="card2.entity == 'location'" class="marketplace-item-content-address pt-2 card_icon_text">
                          <img
                            class="mr-2 card_icon_map"
                            src="/svg/cardLocation.svg"
                          />
                          <p class="ma-0 card_text_map">
                            {{ card2.address }}&nbsp;{{ card2.city }},&nbsp;{{
                              card2.state
                            }}&nbsp;{{ card2.zipCode }}
                          </p>
                        </div> -->

                        <!-- DISTANCE CHIP -->
                        <!-- <div  class="distance_chip ml-8">
                          <img class="ml-1 mr-2 card_icon" src="/svg/car.svg"/>
                          &nbsp;{{ distance_format(card.distance) }} miles from you
                        </div> -->

                        <!-- CHIP" Covered/Uncovered -->
                        <!-- <div v-if="card.covered" class="distance_chip ml-8">
                          {{  card.covered ? 'Covered' : 'Uncovered' }}&nbsp;
                          <img class="ml-1 mr-2 card_icon" :src="`/svg/${card.covered ? 'coveredIcon.svg' : 'uncoveredIcon.svg'}`"/>
                        </div> -->

                        <!-- <div v-show="card2.entity == 'location'"> -->
                          <!-- APPOINTMENT -->
                          <!-- <div class="marketplace-item-content-category pt-2 card_icon_text" v-if="card2.newPatients">
                            <img class="mr-2 card_icon_map" src="/svg/calendar_alt.svg"/>
                            <p class="ma-0 card_text card_text_map">
                              Next day appointments typically available
                            </p>
                          </div> -->

                          <!-- NOT ACCEPTING NEW PATIENTS -->
                          <!-- <div class="marketplace-item-content-category pt-2 card_icon_text" v-else-if="!card2.newPatients">
                            <img class="mr-2 card_icon_map" src="/svg/info_circle.svg"/>
                            <p class="ma-0 card_text card_text_map">
                              Not accepting new patients
                            </p>
                          </div> -->

                          <!-- PROFILE EXTRA INFO -->
                          <!-- <div class="marketplace-item-content-category pt-2 card_icon_text">
                            <img class="mr-2 card_icon_map" src="/svg/map_marker_smile.svg"/> -->

                            <!-- SEE IF card.name HAS "NICE" -->
                            <!-- <p class="ma-0 card_text card_text_map">
                              {{ card2.homeCare == true ? "In Home Care;" : "At-Clinic;" }}
                              Virtual; Text/Chat; Phone; At Work
                            </p>
                          </div>
                        </div>
                      </div> -->

                      <!-- BUTTONS -->
                      <!-- <div class="card_info_buttons_map"> -->

                        <!-- Show Membership Status -->
                        <!-- <button class="card_buttons card_button_disabled map_window_btn" v-if="memberInfo?.subscriberRelation == 'Self' && card2?.entity == 'location' && memberInfo?.providerId == card2?.id && memberInfo?.locationId == card2?.locationId && memberInfo?.setup != 'incomplete'" disabled>
                          {{ userInfo?.subscription?.status == "active" ? "Active" : "Pending Membership" }}
                        </button> -->

                        <!-- Confirming Eligibility -->
                        <!-- <button class="card_buttons map_window_btn" v-if="memberInfo?.nextStep == 'confirmPlan' && card2?.entity == 'location' && card2?.newPatients && memberInfo?.locationId == card2?.locationId"  @click="goToRegister(card2.id, card2, card2.entity)" style="white-space:nowrap;">
                          Confirm eligibility
                        </button> -->

                        <!-- Select Button -->
                        <!-- <button class="card_buttons" v-else-if="card2?.entity == 'location' && card2?.newPatients"  @click="goToRegister(card2.id, card2, card2.entity)">
                          Select
                        </button> -->

                        <!-- Join Waitlist -->
                        <!-- <div class="card_button_joinwaitlist map_window_btn" v-else-if="!card2.newPatients" @click="joinWaitlist(card2.locationId)">
                          Join the waitlist 
                        </div> -->
                        
                        <!-- View Details -->
                        <!-- <button class="map_btn card_buttons map_window_btn" @click="goToDetails(card2, card2.entity)">
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </v-card>

                <div
                  v-else-if="cardLoaded"
                  style="width: 100%; text-align: center"
                >
                  <v-progress-circular
                    indeterminate
                    :size="50"
                    color="primary"
                    class="text-center mt-5 mb-5"
                  ></v-progress-circular>
                </div>

                <v-card v-else>
                  <div>You are here</div>
                </v-card>
              </div>
            </GmapInfoWindow>
          </GmapMap>
        </div>
      </div> -->

      <!-- <router-view></router-view> -->
    </div>

    <!-- FILTERS -->
    <div v-if="drawer" class="filter_bg" :style="`width: ${loggedIn ? 'calc(100% - 225px);':'100%;'}`"></div>

    <v-navigation-drawer class="filter_container" :value="drawer" touchless :right="!$vuetify.rtl" width="350" app>
      <v-card outlined color="transparent">
        
          <!-- Filter Header -->
          <div class="d-flex align-center header_header">
            <span style="font-size:24px;">Filter solutions</span>
            <v-btn icon small @click="clearSearch('closeOnly')">
              <v-icon right color="white" class="mr-1 ml-0"> mdi-close </v-icon>
            </v-btn>
          </div>

          <!-- Filter Contents -->
            <div class="filter_scorll">
              <v-card-text class="pt-0 content-height" style="padding-bottom: 20vh">

                <v-expansion-panels accordion multiple v-model.trim="panel" class="no-shadow no-padding user-expansion-panels">

                  <!-- Search by name -->
                  <div class="filter_not_accordion">
                    <div class="filter_not_accordion_title">
                      Search by name
                    </div>

                    <!-- Search By Name Input Field -->
                    <div class="no-padding content-filter">
                      <v-text-field prepend-inner-icon="mdi-magnify" @change="searchByNameChange()" v-model="searchByName" class="mr-0 md-3 mb-2" light dense outlined hide-details placeholder="Enter organization or provider name" style="border-radius: 10px; font-size: 14px"></v-text-field>
                    </div>
                    
                  </div>

                  <!-- Distance -->
                  <div class="filter_not_accordion">
                    <div class="filter_not_accordion_title">
                      Distance from me
                      <!-- <p class="mr-auto"> Distance from me </p>    -->
                      <!-- <p class="text-gray">{{ searchDistance }} miles</p> -->
                    </div>
                    <div>
                      <v-slider
                        v-model="searchDistance"
                        :tick-labels="tickLabels"
                        min="0"
                        max="9"
                        step="1"
                        tick-size="1"
                        ref="custom_slider"
                      ></v-slider>
                    </div>
                  </div>

                  <!-- Availability -->
                  <div class="filter_not_accordion">
                    <div class="filter_not_accordion_title">
                      Availability
                    </div>
                    <div class="no-padding content-filter mb-1">
                      <v-checkbox
                        class="checkbox-label-size pa-0"
                        hide-details
                        v-model="newPatients"
                        label="Only show clinics accepting new patients"
                        :true-value="true"
                        :false-value="false"
                      >
                      </v-checkbox>
                    </div>
                  </div>

                  <!-- Pediatrics -->
                  <div class="filter_not_accordion">
                    <div class="filter_not_accordion_title">
                      Pediatrics
                    </div>
                    <div class="no-padding content-filter">
                      <v-checkbox
                        class="checkbox-label-size pa-0"
                        hide-details
                        v-model="pediatrics"
                        label="Only show clinics that offer pediatric services"
                        :true-value="true"
                        :false-value="false"
                      >
                      </v-checkbox>
                    </div>
                  </div>

                  <!-- Specialty -->
                  <!-- <v-expansion-panel class="no-shadow no-padding pt-6 pb-6">
                    
                    <v-expansion-panel-header class="font-18 mb-2 font-weight-black change-padding-filter filter-items-font">
                      Specialty
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="no-padding content-filter">
                      <v-text-field prepend-inner-icon="mdi-magnify" v-model="findSpeciality" class="mr-0 mr-md-3 mb-2" light dense outlined hide-details placeholder="Search Specialties" style="border-radius: 10px; font-size: 14px"
                      ></v-text-field>
                      <div class="pa-0 ma-0 mb-3" :class="showAllSpecialities ? 'scrollable-creds' : ''" >
                        <v-checkbox class="checkbox-label-size pa-0" hide-details v-for="(specialties, i) in filteredSlicedSpecialities" :key="i" v-model="selectedSpecialities" :label="specialties" :value="specialties">
                        </v-checkbox>
                      </div>
                      <a v-if="!showAllSpecialities" @click="showMoreSpecialities()">
                        <small class="font-14 underline-text pb-6">
                          See more
                          <v-icon small color="primary">mdi-chevron-down</v-icon>
                        </small>
                      </a>
                      <a v-else-if="showAllSpecialities"
                        @click="showLessSpecialities()">
                        <small class="font-14 underline-text pb-6" >
                          See less
                          <v-icon small color="primary">mdi-chevron-up</v-icon>
                        </small>
                      </a>

                    </v-expansion-panel-content>
                    
                  </v-expansion-panel> -->

                  <!-- Organization -->
                  <v-expansion-panel class="no-shadow no-padding pt-6 pb-6">
                    
                    <v-expansion-panel-header class="font-18 mb-2 font-weight-black change-padding-filter filter-items-font">
                      Group and Organization
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="no-padding content-filter">
                      <v-text-field prepend-inner-icon="mdi-magnify" v-model="searchGroupOrOrganization" class="mr-0 mr-md-3 mb-2" light dense outlined hide-details placeholder="Search Specialties" style="border-radius: 10px; font-size: 14px"
                      ></v-text-field>
                      <div class="pa-0 ma-0 mb-3" :class="showAllGroups ? 'scrollable-creds' : ''" >
                        <v-checkbox class="checkbox-label-size pa-0" hide-details v-for="(groups, i) in filteredSlicedGroupAndOrganizations" :key="i" v-model="selectedGroupAndOrganizations" :label="groups" :value="groups">
                        </v-checkbox>
                      </div>
                      <a v-if="!showAllGroups" @click="showMoreGroups()">
                        <small class="font-14 underline-text pb-6">
                          See more
                          <v-icon small color="primary">mdi-chevron-down</v-icon>
                        </small>
                      </a>
                      <a v-else-if="showAllGroups"
                        @click="showLessGroups()">
                        <small class="font-14 underline-text pb-6" >
                          See less
                          <v-icon small color="primary">mdi-chevron-up</v-icon>
                        </small>
                      </a>

                    </v-expansion-panel-content>
                    
                  </v-expansion-panel>

                  <!-- Provider -->
                  <v-expansion-panel class="no-shadow no-padding pt-6 pb-6">

                    <v-expansion-panel-header class="font-18 mb-2 font-weight-black change-padding-filter filter-items-font">
                      Provider
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="no-padding content-filter">
                      <h4 class="text-start mb-0 mt-2 mb-1">Gender of provider</h4>
                      <div class="d-flex justify-space-between pb-0 mb-0 mb-2" style="align-items: center; height: 28px">
                        <p class="mb-0 pb-1">Female</p>
                        <v-switch class="mb-0 mt-1" v-model="selectedGenders" value="F"></v-switch>
                      </div>
                      <div class="d-flex justify-space-between mb-4" style="align-items: center; height: 24px">
                        <p>Male</p>
                        <v-switch v-model="selectedGenders" value="M"> </v-switch>
                      </div>
                    </v-expansion-panel-content>
                    
                  </v-expansion-panel>

                  

                </v-expansion-panels>

          

                <!-- <v-list-item style=" border-top: 1px solid #cccccc; background: white; width: 100%;" class="mt-2">
                  <v-list-item-content class="justify-start">
                    <v-list-item-title class="d-flex justify-start">
                      <v-btn class="mr-5 font-weight-500 font-secondary" color="transparent" @click="clearSearch('clearFilter')">
                        Clear All
                      </v-btn>
                      <v-btn class="text-white" color="#0069F3" style="border-radius: 8px" @click="searchFilters()">
                        View results
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

              </v-card-text>

            
            </div>
            <div class="filter_buttons_container">
                <div class="filter_buttons">
                  <v-btn class="mr-5 font-weight-500 font-secondary" color="transparent" @click="clearSearch('clearFilter')">
                    Clear All
                  </v-btn>
                  <v-btn class="text-white" color="#0069F3" style="border-radius:8px; box-shadow:none;" @click="searchFilters()">
                    View results
                  </v-btn>
                </div>
              </div> 
                <!-- Filter Buttons -->
                <v-card-text>
        
                </v-card-text>
              
        
      </v-card>
    </v-navigation-drawer>

    <!-- DIALOGS -->

    <!-- Remind user to select Employer, first -->
    <v-dialog v-model="needEmployer" width="500px" persistent>
      <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon
          role="button"
          @click="needEmployer = false"
          v-text="'mdi-close'"
          slot="end"
        ></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_primary">Employer Confirmation</div>
        <div class="dialog_subtext dialog_second" style="font-weight:bold;color:#333333">
          Please select your current employer so we can personalize your provider directory

          <!-- Select Employer -->
          <div class="mt-5" style="text-align:left;">
            <span>Search and select your employer</span>  
            <div style="border:1px solid #ccc;border-radius:6px;" class="mt-1">
              <v-select
                id="employerSelect"
                ref="employerSelect"
                v-model="selectedEmployerInfo"
                :items="employers"
                placeholder="Select your employer"
                @click:clear="clearSearchEmployer"
                @click="focusSearchInput()"
                item-value="id"
                return-object
                :rules="requiredRules"
                class="header_marketplace_fields"
                no-data-text="No employer found"
                v-click-outside="onVSelectClickOutside"
                solo
                flat
                dense
                clearable
                required
                hide-details
            >

                <template v-slot:prepend-item>
                    <div class="custom_select_input_container">
                        <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search employer here" @input="searchingEmployers"/>
                    </div>
                </template>

                <!-- Show Selected Employer -->
                <template #selection="{ item }">
                    {{ item.companyName }}
                </template>

                <!-- List of Employers -->
                <template #item="{ item }">
                    <div>{{ item.companyName }}</div>
                    <!-- <div :class="{ 'active-item': 'active' }">{{ item.companyName }} </div> -->
                </template>

                <template #item-disabled="{ item }">
                    <div>{{ item.companyName }}</div>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="dialog_buttons">
          <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="needEmployer = false">
            Cancel
          </button>
          <button class="dialog_single_button dialog_btn_primary" @click="selectedEmployer()">
            Submit
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- Enter email to get notified -->
      <v-dialog v-model="notifyMe" width="500px" persistent>

        <v-card class="dialog_layout">
          
          <v-card-title class="d-flex justify-end">
            <v-icon role="button" @click="notifyMe = false" v-text="'mdi-close'" slot="end"></v-icon>
          </v-card-title>

          <v-card-text class="dialog_content">
            <div class="dialog_title dialog_primary" style="color: #333333">
              This location is not accepting new patients at the moment
            </div>
            <div class="dialog_subtext dialog_second">
              Enter your e-mail and we'll notify you as soon as this clinic is accepting new patients again
            </div>
            <div class="dialog_input">
              <div v-if="!loggedIn" style="font-size: 18px" class="mt-5 mb-3">Email</div>
              <input v-if="!loggedIn" @input="delayCheckmail()" v-model="unregisteredEmail" placeholder="Enter email" dense type="email" style="width:100%" class="marketpalce_notice_input"/>
              <v-checkbox
                class="dialog_checkbox mb-5 mt-5"
                hide-details
                v-model="allowApalyToContact"
                label="I agree to be contacted by Apaly"
                :true-value="true"
                :false-value="false"
              >
              </v-checkbox>
            </div>
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_primary" :disabled="!validEmail || !allowApalyToContact" @click="saveEmailAndNotifyMe()" >
                Notify me
              </button>
            </div>
          </v-card-text>

        </v-card>

    </v-dialog>

    <!-- Successfully added to waitlist -->
    <v-dialog v-model="addedToWaitlist" width="500px" persistent>
      <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon
          role="button"
          @click="addedToWaitlist = false"
          v-text="'mdi-close'"
          slot="end"
        ></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="text-center mb-5">
          <img src="/svg/email_sent.svg" />
        </div>
        <div class="dialog_title dialog_primary">You've been added to the waitlist</div>
        <div class="dialog_subtext dialog_second">
          We'll notify you as soon as this clinic is available and accepting new patients.
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary mt-5" @click="reloadMarketpalce()" >
            Find a different provider
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- Successfully added to notify if clinic is near user's location -->
    <v-dialog v-model="addToNotifyNearClinic" width="500px" persistent>
      <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon
          role="button"
          @click="addToNotifyNearClinic = false"
          v-text="'mdi-close'"
          slot="end"
        ></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="text-center mb-5">
          <img src="/svg/email_sent.svg" />
        </div>
        <div class="dialog_title dialog_primary">We'll notify you.</div>
        <div class="dialog_subtext dialog_second">
          We will notify you when there are new locations near you or when we find a virtual option.
          <br><br>
          You can also try adjusting your search filters for more service options. 
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary mt-5" @click="reloadMarketpalce()" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- You have an pending/active membership -->
    <v-dialog v-model="cannotEnrollModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="cannotEnrollModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
            <div class="dialog_title dialog_nb">
                You have an active membership with <span class="dialog_blue">{{ memberInfo?.location?.name }}</span>
            </div>
            <div class="dialog_subtext dialog_second mt-5">
                Please contact us through our support email at
                <strong class="dialog_blue">support@apaly.com</strong> for further assistance.
            </div>
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_primary" @click="cannotEnrollModal = false">
                Got It
              </button>
            </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Change provider -->
    <v-dialog v-model="changeProviderModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="changeProviderModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_nb">
            {{ selectedLocationToChange?.title }}
            <span class="dialog_blue">
                {{ selectedLocationToChange?.oldLocation }}
            </span>
          </div>
          <div class="dialog_subtext dialog_second mt-3">
            Are you sure you want to change your
             <span v-if="selectedLocationToChange?.isDependent"> dependent's</span>
            provider to <br>
            <span class="dialog_orange">{{ selectedLocationToChange?.name}}</span>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="changeProviderModal = false">
              Cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="updateProvider()">
              Yes
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Updated provider -->
    <v-dialog v-model="changedProviderModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="changedProviderModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success..! You’ve updated your <span v-if="selectedLocationToChange?.isDependent">dependent's</span> provider.
          </div>
          <div class="dialog_subtext dialog_second mt-3">
             You<span v-if="selectedLocationToChange?.isDependent">r dependent</span>
             now have a pending membership with <br>
            <span class="dialog_orange">{{ selectedLocationToChange?.name }}</span>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="changedProviderModal = false">
              Got It
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dependent all set modal -->
    <v-dialog v-model="dependnetAllSetModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="redirect()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">Family member enrolled</div>
          <div class="dialog_subtext dialog_second">
            Would you like to add another family member?
          </div>
          <div class="dialog_buttons">
            <button
              class="dialog_double_button dialog_btn_second dialog_btn_floatLeft"
              @click="goToMembershipCard()"
            >
              I'm all set
            </button>
            <button
              class="dialog_double_button dialog_btn_primary dialog_btn_floatRight"
              @click="addDependentAfterEnroll()"
            >
              Add another
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NO Custom clinic redirect modal (Subscriber's Modal) -->
    <v-dialog v-model="subscriberAllSetModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary" >
            Success! You're enrolled with {{ memberInfo.location?.locationName }}
          </div>
          <div class="dialog_subtext dialog_second">
            You now have access to your own personal provider! <br><br>
            The clinic will reach out to schedule your first appointment.  
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToMembershipCard()" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dependent error modal -->
    <v-dialog v-model="dependnetErrorModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="dependnetErrorModal = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            <!-- Employer's plan is not covered by this provider. -->
            {{ errorMessage.message ? errorMessage.message : errorMessage }}
          </div>
          <div class="dialog_buttons">
            <button
              class="dialog_single_button dialog_btn_primary"
              @click="dependnetErrorModal = false"
            >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NO EMAIL -->
    <v-dialog v-model="noEmail" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="noEmail = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            {{ `${memberInfo.firstName} ${memberInfo.lastName} has no email` }}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="noEmail = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Select provider modal -->
    <v-dialog v-model="selectProvider" width="500px">
      <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon
          role="button"
          @click="selectProvider = false"
          v-text="'mdi-close'"
          slot="end"
        ></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="select_provider_title text-center mb-5">
            Perfect!
        </div>
        <div class="text-center select_provider_subtitle">
            We verified that your employer offers APC as a part of your health plan. Now, let's find an APC provider near you.
        </div>
        <!-- <div class="dialog_subtext dialog_second">
          We'll notify you as soon as this clinic is available and accepting new patients.
        </div> -->
        <div class="dialog_buttons">
          <button class=" mt-5 mb-2 dialog_btn_primary_or_sm" @click="selectProvider=false" >
            Find My Provider
          </button>
        </div>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- Generic Error Modal -->
    <v-dialog v-model="genError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="genError = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            {{ genErrorMess }}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="genError = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Error Modal -->
    <errorModal v-if="somethingWrong" :someThingWentWrong="somethingWrong" :errorMess="erroMessage" ref="error_modal" ></errorModal>

  </div>
</template>


<script>
// var _paq = window._paq || [];
// _paq.push(["trackPageView"]);
import api from "../../../../services/api";
import AllMarketplaceItems from "./AllMarketplaceItems.vue";
import axios from "axios";
// import AllClinicians from "./AllClinicians.vue";
// import AllClinics from "./AllClinics.vue";
import MySolutions from "./MySolutions.vue";
import { mapState } from "vuex";
import Mixpanel from "mixpanel-browser";
import errorModal from "../../../Common/SomethingWentWrong.vue";
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';

import vClickOutside from 'v-click-outside';
export default {
  components: {
    AllMarketplaceItems,
    MySolutions,
    errorModal,
    FullscreenLoader,
  },
  data() {
    return {
      needEmployer: false,
      map: null, // To store the map instance
      mapBounds: null, // Will contain Northeast and Southwest
      mapsApi: null,
      showMap: false,
      genError: false,
      genErrorMess: '',
      didFilter: false,
      // removeFilterChips: false,
      // tickLabels: {
      //   0: '0',
      //   1: '5',
      //   2: '10',
      //   3: '15',
      //   4: '20',
      //   5: '25',
      //   6: '30',
      //   7: '35',
      //   8: '40',
      //   9: '45',
      //   10: '50',
      // },
      // tickLabels: {
      //     0: 'Figs',
      //     1: 'Lemon',
      //     2: 'Pear',
      //     3: 'Apple',
      //   },
      mapOptions: {
        disableDefaultUi: true, // Disable the default UI
        mapTypeControl: false, // Disable the map type control (including Satellite view)
        gestureHandling: 'greedy'
      },
      validEmail: false,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
          "Please enter a valid e-mail",
      ],
      tickLabels: [
        "0",
        10,
        15,
        20,
        25,
        30,
        35,
        40,
        45,
        50,
      ],
      noEmail: false,
      searchByName: "",
      searchDistance: 50, // Default
      notifyMe: false,
      addedToWaitlist: false,
      addToNotifyNearClinic: false,
      allowApalyToContact: false,
      selectedLocationForWaitlist: "",
      unregisteredEmail: "",
      provider: [],
      somethingWrong: false,
      erroMessage: "",
      infoOptions: {
        disableAutoPan: true,
      },
      // alreadyHaveProvider: false,
      notifyMe: false,
      errorMessage: "",
      selectingProvider: false,
      dependnetErrorModal: false,
      subscriberAllSetModal: false,
      cannotEnrollModal: false,
      dependnetAllSetModal: false,
      autocomplete: null,
      showMap: true,
      urlLocation: null,
      urlCompany: null,
      solutions: "all_solutions",
      tab: "clinics",
      tab: "clinics",
      searchQuery: "",
      zipOrState: "",
      employer: "",
      // loader: false,
      noResultsFromSearch: false,
      // marketplace: [],
      // clinicians: [],
      // clinics: [],
      items: [],
      tabTitle: "Direct Primary Care",
      showAllCredentials: false,
      showAllSpecialities: false,
      showAllGroups: false,
      panel: [0, 1, 2, 3, 4, 5],
      drawer: false,
      selectedCategories: [],
      selectedCredentials: [],
      selectedSpecialities: [],
      selectedGroupAndOrganizations: [],
      selectedGenders: [],
      searchByCategory: [],
      providerCredentials: [],
      slicedProviderCredentials: [],
      specialities: [],
      slicedSpecialities: [],
      groupAndOrganizations: [],
      slicedGroupAndOrganizations: [],
      newPatients: false,
      pediatrics: false,
      findSpeciality: "",
      searchCredentials: "",
      searchGroupOrOrganization: "",
      serviceTypeCodes: [],
      selectedServiceTypeCodes: [],
      changeFilterHeightFor700: false,
      changeFilterHeightFor800: false,
      itemPerPage: 6,
      pageNumber: 1,
      Ip: null,
      hasNextPage: false,
      // totalPages: null,
      currentPage: 1,
      pagesToShow: 4,
      // center: {
      //   lat: 0,
      //   lng: 0
      // },
      // userCenter: {
      //   lat: 0,
      //   lng: 0
      // },
      // places: [],
      infoWindowOpened: false,
      infoWindowPosition: null,
      infoWindowContent: {
        name: "",
        description: "",
      },
      selectedPlace: null,
      centerData: {
        name: "Struga",
        description: "your address ",
      },
      card: null,
      card2: null,
      showUser: true,
      fromSearch: false,
      // searchBy: 'normal', // T.N. used in pagination to determine which method to use. enum: ['normal', 'address', 'filters']
      employers: [],
      address: this.$store.state.searchedAddress, //Whatever value state.searchedAddress has, will be the default value for addrss
      aliasUrl: null,
      isCustomURL: false,
      mapLoaded: false,
      userInfo: null,
      all: [],
      maxDistance: 100,
      slider: 1,
      cardLoaded: false,
      selectProvider: false,
      marketplaceViewMapMobile: false,
      screenWidth: window.innerWidth,
      mobileBreakpoint: 600,
      onMobile: false,
      changeProviderModal: false,
      selectedLocationToChange: null,
      changedProviderModal: false,
      familyDetails: null,
      dependentProvider: null,
      employersCopy: [],
      allEmployers: [],
      selectedEmployerInfo: null,
      empl: null,
      searchTerm: null,
      hasEmployer: null,
      userZipcode: null,

      requiredRules: [(v) => !!v || "This field is required"],

    };
  },
  async mounted() {
    // Mixpanel Integration Testing
    Mixpanel.track("Mixpanel Integration Test: Marketpalce has been loaded");

    if (typeof google === "undefined") {
      const timer = setInterval(() => {
        if (typeof google !== "undefined") {
          clearInterval(timer);
          this.setUpGoogleMaps();
        }
      }, 100);
    } else {
      this.setUpGoogleMaps();
    }

    // set from store
    this.pageNumber = this.lastPageNumber;

    // gmap
    this.$refs.gmap.$mapPromise.then((map) => {
      // console.log(map);

      // Prevent PIOs from being displayed if clicked
      map.addListener("click", function (event) {
        // Check if the clicked feature is a POI
        if (event.placeId) {
          // Prevent the default behavior (clickability) for POIs
          event.stop();
        }
      });

      // Hide PIOs
      // map.setOptions({
      //   styles: [
      //     {
      //       featureType: 'poi.business',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.park',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.government',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.school',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.school',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.place_of_worship',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.sports_complex',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.medical',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'poi.attraction',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'landscape.natural',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'landscape.man_made',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //     {
      //       featureType: 'transit',
      //       elementType: 'labels.icon',
      //       stylers: [{ visibility: 'off' }],
      //     },
      //   ]
      // })
    });

    window.addEventListener('resize', this.updateScreenWidth);

    // Create Custom Button
    setTimeout(() => {
      this.mapButton();
    }, 500);
  },
  async created() {
    // TEMPORARY
    // this.$store.commit( "showSidebar", localStorage.getItem("user") && this.$cookies.get("token") ); // Will not use this anymore

    // // Prevent access on marketplace when not logged in
    // if (!$cookies.get('token')) {
    //     this.$router.push('/')
    // }

    this.onMobile = this.screenWidth <= this.mobileBreakpoint;

    try {
      // if (this.$matomo && this.$matomo.trackPageView) {
      //   this.$matomo.trackPageView({ customTitle: "Member Marketplace" });
      // } else {
      //   console.error("Matomo object or trackPageView method is not defined.");
      // }
      // Selecting Provider for Dependents
      if (this.$store.getters.getDependentId) {
        // console.log("marketplace dependent");
        this.dependentId = this.$store.getters.getDependentId;
      }
      // Selecting Provider for User/Member
      // getMemberId needs to be setup in stores
      // if(this.$store.getters.getMemberId){
      //   console.log('marketplace user/member')
      //   this.memberId = this.$store.getters.getMemberId;
      // }
      this.getUserLocation();
      this.getEmployers();

      this.hasEmployer = this.$store.getters.getSelectedEmployer;

        // Auto update employer field
        if (this.hasEmployer && this.loggedIn) {
            this.selectedEmployerInfo = this.hasEmployer;
            this.empl = this.hasEmployer;
        } else if (this.memberInfo && this.memberInfo.employer) {
          this.selectedEmployerInfo = this.memberInfo.employer;
            this.empl = this.memberInfo.employer;
            this.$store.commit("setSelectedEmployer", this.empl);
            this.hasEmployer = this.empl;
        }

      // Update State
      this.$store.commit("setSelectedEmployerInfo", this.selectedEmployerInfo);

      if (this.places.length < 1) this.getAllPlaces(); 
      if (this.providerCredentials.length < 1) this.getProviderCredentials();
      if (this.specialities.length < 1) this.getSpecialities();
      if (this.groupAndOrganizations.length < 1) this.getGroupAndOrganizations();

      const ipResponse = await axios.get(
        "https://api64.ipify.org/?format=json"
      );

      if (ipResponse.status === 200) {
        this.Ip = ipResponse.data.ip;
        const IP_ADDRESS = ipResponse.data.ip;

        const locationResponse = await fetch(
          `https://ipinfo.io/${IP_ADDRESS}/json?token=c0bab02a62645b`
        );

        const jsonData = await locationResponse.json();

        this.userZipcode = jsonData.postal
        const loc = jsonData.loc;

        const [latitude, longitude] = loc.split(",");
        // this.userCenter.lat = parseFloat(latitude);
        // this.userCenter.lng = parseFloat(longitude);
        // this.center.lat = parseFloat(latitude);
        // this.center.lng = parseFloat(longitude);
        this.$store.commit("setUserLocation", {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
        });

        // T.N. No need to use Promise.all because all these methods are async and not dependent on each other
        // if (this.userCenter.lat !== 0 && this.userCenter.lng !== 0) {
        //   await Promise.all([
        //     this.getUserLocation(),
        //     this.getEmployers(),
        //     this.getAllPlaces(),
        //     this.getMarketplace(),
        //     this.getProviderCredentials(),
        //     this.getSpecialities(),
        //     this.getGroupAndOrganizations(),
        //   ]);

        // }
      }
      // else {
      //   await Promise.all([
      //     this.getUserLocation(),
      //     this.getEmployers(),
      //     this.getAllPlaces(),
      //     this.getMarketplace(),
      //     this.getProviderCredentials(),
      //     this.getSpecialities(),
      //     this.getGroupAndOrganizations(),
      //   ]);
      // }
      if (this.marketplace.length < 1) this.getMarketplace(); // call this after attempt to get user location
    } catch (error) {
      console.error("Error fetching IP:", error);
    }

    // THIS IS TO HAVE UNREMOVABLE CHIP WHEN ACCESSING CUSTOM URL
    if (this.$route.query.company) {
      this.isCustomURL = true;
      this.$store.commit("setCustomCompany", true);
    } else {
      this.$store.commit("setCustomCompany", false);
    }
    
    if (this.$route.query.selectProvider) {
        this.selectProvider = true;
    }

    this.$store.commit("setMarketSearchFilters", { searchDistance: 50 });

    this.getDependentInfo()
    this.searchAddress(null, true);
  },
  beforeRouteUpdate(to, from, next) {
    // this.getMarketplace();
    next();
  },
  computed: {
    ...mapState({
      searchBy: (state) => state.searchBy,
      marketplace: (state) => state.marketplace,
      clinics: (state) => state.clinics,
      clinicians: (state) => state.clinicians,
      totalPages: (state) => state.totalPages,
      lastPageNumber: (state) => state.pageNumber,
      center: (state) => state.mapCenter,
      userCenter: (state) => state.userLocation,
      loader: (state) => state.loader,
      places: (state) => state.mapPins,
      memberInfo: (state) => state.memberInfo,
      dependentInfo: (state) => state.dependentInfo,
    }),
    slicedMarketplace() {
      let start = (this.pageNumber - 1) * 6;

      switch (this.tab) {
        case "all":
          return this.marketplace;
        case "clinics":
          return this.clinics;
        default:
          return this.clinicians.slice(start, start + 6);
      }
      if (this.tab === "clinics" || this.tab == "all") {
        // Display only clinics
        return this.marketplace;
      } else if (this.tab === "clinicians") {
        // Display only clinicians
        return this.clinicians.slice(start, start + 6);
      } else {
        // Display a combination of clinics and clinicians for tab ALL
        let slice = this.clinics.slice(start, start + 6);
        let locationIds = slice.map((item) => item.locationId);
        let docs = this.clinicians.filter((item) =>
          locationIds.includes(item.locationId)
        );
        return [...slice, ...docs];
      }
    },
    visiblePages() {
      const startPage = Math.max(
        1,
        this.pageNumber - Math.floor(this.pagesToShow / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + this.pagesToShow - 1
      );
      return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      );
    },
    services() {
      const uniqueServices = new Map();
      this.items.forEach((item) => {
        if (item.services) {
          item.services.forEach((service) => {
            if (
              service.serviceTypeCode &&
              !uniqueServices.has(service.serviceTypeCode)
            ) {
              uniqueServices.set(service.serviceTypeCode, service);
            }
          });
        }
      });
      return Array.from(uniqueServices.values());
    },
    filteredSlicedSpecialities() {
      if (this.findSpeciality != "") {
        this.selectedSpecialities = [];
        return this.specialities.filter((item) => {
          return item.toLowerCase().includes(this.findSpeciality.toLowerCase());
        });
      } else {
        // this.selectedSpecialities = [];
        return this.slicedSpecialities;
      }
    },
    filteredSlicedProviderCredentials() {
      if (this.searchCredentials != "") {
        this.selectedCredentials = [];
        return this.providerCredentials.filter((item) => {
          return item
            ?.toLowerCase()
            .includes(this.searchCredentials.toLowerCase());
        });
      } else {
        // this.selectedCredentials = [];
        return this.slicedProviderCredentials;
      }
    },
    filteredSlicedGroupAndOrganizations() {
      // This is the old filtering logic
      if (this.searchGroupOrOrganization != "") {
        this.selectedGroupAndOrganizations = [];
        return this.groupAndOrganizations.filter((item) => {
          return item
            ?.toLowerCase()
            .includes(this.searchGroupOrOrganization.toLowerCase());
        });
      } else {
        if (this.$route.query.company) {
          this.selectedGroupAndOrganizations = [];
        }
        return this.slicedGroupAndOrganizations;
      }

      // No need to use the checkbox and select the Provider anymore
      // since Providers will be expected to increase that will cause
      // too much checklist in Group and Organization filter
      // if (this.searchGroupOrOrganization != "") {
      //   // Direct search after typing
      //   return (this.selectedGroupAndOrganizations = [
      //     this.searchGroupOrOrganization,
      //   ]);
      // } else {
      //   // This is for the custom Provider Link (param on address bar)
      //   if (!this.$route.query.company) {
      //     // this.selectedGroupAndOrganizations = [];
      //   }
      //   return this.slicedGroupAndOrganizations;
      // }
    },
    loggedIn() {
      return this.memberInfo && this.$cookies.get("token");
    },
    isMobile() {
        return this.screenWidth <= this.mobileBreakpoint;
    },
    currentMemberInfo() {
        if (this.dependentInfo && this.dependentInfo.nextStep) {
            return this.dependentInfo;
        }
        return this.memberInfo;
    }
  },
  watch: {
    '$store.state.selectedEmployerInfo'(newValue, oldValue) {
      if(newValue) {
        // Set Employer
        this.selectedEmployerInfo = newValue;
        this.empl = newValue;

        // Search Marketplace
        this.searchResults();
      }
    },
    memberInfo(newVal) {
        if (newVal && newVal.employer) {
            this.selectedEmployerInfo = newVal.employer;

            // Set to STATE
            this.$store.commit("setSelectedEmployerInfo", this.selectedEmployerInfo);
        }
    },
    places(nV, oV) {
      const falseCount = this.places.filter(place => place.newPatients === false).length;
      const trueCount = this.places.filter(place => place.newPatients === true).length;
      // console.log("Number of places with newPatients set to false:", falseCount);
      // console.log("Number of places with newPatients set to true:", trueCount);
      // console.log("Total Number of places:", this.places.length);
    },
    // Removed since Map Pins are now having the newPatient object being returned from BE
    // marketplace(nV, oV) {
    //   if(nV?.length !== 0) {
    //     // console.log("Marketplace: ", nV);
    //     const newPin = [];
    //     const places = this.places;
    //     const placesCopy = this.places;

    //     for (const markatplace of nV) {
    //       // const mID = markatplace.id;
    //       const Mlat = parseFloat(markatplace?.coordinates?.lat);
    //       const Mlng = parseFloat(markatplace?.coordinates?.lng);

    //       // Find the providers are searched
    //       const matchedPlaces = places.find(loc => loc.coordinates.lat == Mlat && loc.coordinates.lng == Mlng);

    //       // Based on marketplace.newPatient, add the same data
    //       if (matchedPlaces) {
    //         matchedPlaces.newPatient = markatplace.newPatients;
    //         // console.log("matchedPlaces: ", matchedPlaces);
    //         newPin.push(matchedPlaces);
    //       }
            
    //     }
    //     for (const matchedPlaces of newPin) {
    //       // Based on matchedPlaces.id, remove data from placesCopy if placesCopy.id matches
    //       const newMatches = placesCopy.find(loc => loc.id == matchedPlaces.id);
    //       if (newMatches !== -1) {
    //         placesCopy.newPatient = newPin.newMatches; // Update content
    //       }
    //     }
    //     // console.log("newPin: ", newPin);
    //     // console.log("placesCopy: ", placesCopy);

    //     // Updated Map Pins
    //     this.$store.commit("setMapPins", placesCopy);
    //   }
    // },
    notifyMe(nV, oV) {
      if(nV == false) {
        this.unregisteredEmail = "";
        this.allowApalyToContact  = false;
      }
    },
    pageNumber(newVal, oldVal) {
      // console.log('watch pageNumber', oldVal, newVal)
      if (this.tab != "clinicians" && this.pageNumber != this.lastPageNumber) {
        this.$store.commit("setPage", newVal);
        if (this.searchBy == "normal") {
            let newDistance;
            if(this.searchDistance) {
                if (this.searchDistance == 1) newDistance = 10;
                else if (this.searchDistance == 2) newDistance = 15;
                else if (this.searchDistance == 3) newDistance = 20;
                else if (this.searchDistance == 4) newDistance = 25;
                else if (this.searchDistance == 5) newDistance = 30;
                else if (this.searchDistance == 6) newDistance = 35;
                else if (this.searchDistance == 7) newDistance = 40;
                else if (this.searchDistance == 8) newDistance = 45;
                else if (this.searchDistance == 9) newDistance = 50;
            }
            
            const metadata = {
                searchByZip: this.extractZipCode(this.address),
                sessionId: `anon_${Date.now()}`,
                member: this.memberInfo.id ? this.memberInfo.id : null,
                address_searched: this.address,
                employer_name: this.empl?.companyName,
                distance_search: newDistance,
                email: this.memberInfo.email ? this.memberInfo.email : null,
                filters: this.convertFiltersToStringArray({
                    searchDistance: newDistance,
                    selectedSpecialities: this.selectedSpecialities,
                    selectedGenders: this.selectedGenders,
                    selectedGroupAndOrganizations: this.selectedGroupAndOrganizations,
                })
            }

            this.$store.dispatch("getMarketplace", {
                query: {
                    ...this.$route.query,
                    userZipCode: this.userZipcode
                },
                metadata
            });
        }
         
        else this.searchResults();
      }
      // console.log('thissote',this.$store.state)
    },
    drawer(nV, oV) {
      if(nV) {
        document.body.style.overflow = 'hidden';
        document.body.style.overflowY = 'hidden';
      }
      else {
        document.body.style.overflow = 'auto';
        document.body.style.overflowY = 'auto';
      }
    },
    // newPatients(newVal, oldVal) {
    //   this.searchFilters();
    // },
    // selectedGenders(newVal, oldVal) {
    //   this.searchFilters();
    // },
    // selectedCredentials(newVal, oldVal) {
    //   this.searchFilters();
    // },
    // selectedGroupAndOrganizations(newVal, oldVal) {
    //   this.searchFilters();
    // },
    // No need for this but is a good future reference
    // '$store.state.searchedAddress'(nVal, oVal) {
    //   console.log(`New Val: ${nVal}, Old Val: ${oVal}`);
    //   this.address = nVal;
    // },
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    mapButton() {
      // Create Custom Button
      const map = this.$refs.gmap.$mapObject;
      this.map = map;

      const customButton = document.createElement('button');
      customButton.innerText = 'Update Search';
      customButton.style.padding = '10px 15px';
      customButton.style.margin = '10px';
      customButton.style.backgroundColor = '#ED6420';
      customButton.style.color = '#FFFFFF';
      customButton.style.border = 'none';
      customButton.style.borderRadius = '5px';
      customButton.style.cursor = 'pointer';
      customButton.style.fontSize = '18px';

      // Add a click event to the button
      customButton.addEventListener('click', () => {
        this.searchByBounds();
      });

      // Add the button to the map's controls
      if (map) {
        map.controls[google.maps.ControlPosition.TOP_CENTER].push(customButton);
      }
    },
    // GET MAP BOUNDS
    onBoundsChanged() {
      // Update bounds when the map bounds change
      this.getBounds();

      // Set map instamce 
      const map = this.$refs.gmap.$mapObject;
      this.map = map;

      // // Create Custom Button
      // const customButton = document.createElement('button');
      // customButton.innerText = 'Update Search';
      // customButton.style.padding = '10px';
      // customButton.style.margin = '10px';
      // customButton.style.backgroundColor = '#007BFF';
      // customButton.style.color = '#FFFFFF';
      // customButton.style.border = 'none';
      // customButton.style.borderRadius = '4px';
      // customButton.style.cursor = 'pointer';

      // // Add a click event to the button
      // customButton.addEventListener('click', () => {
      //   this.searchByBounds();
      // });

      // // Add the button to the map's controls
      // map.controls[google.maps.ControlPosition.TOP_CENTER].push(customButton);
    },
    getBounds() {
      if (this.map) {
        const bounds = this.map.getBounds();
        if (bounds) {
          const southwest = bounds.getSouthWest();
          const northeast = bounds.getNorthEast();
          // console.log('Southwest:', southwest.lat(), southwest.lng());
          // console.log('Northeast:', northeast.lat(), northeast.lng());

          // Set bounds for query
          this.mapBounds = {
            nwLng: northeast.lng(),
            nwLat: northeast.lat(),
            seLng: southwest.lng(),
            seLat: southwest.lat()
          };
        }
        else {
          console.log("No Bounds: ", bounds);
        }
      }
      else {
        console.log("No Map Instance: ", this.map);
      }
    },
    async clearSearchEmployer() {
        this.$store.commit("setAddressSeached", null);
        this.address = null;
        this.empl = null;
        this.searchTerm = null;
        this.selectedEmployerInfo = null
        this.$store.commit("setSelectedEmployerInfo", null);
        this.$store.dispatch("clearMapPins");
    },
    selectedEmployer() {
        // this.getEmployers();
        this.employers = this.allEmployers;
        this.employersCopy = this.allEmployers;
        
        this.empl = this.selectedEmployerInfo;
        this.$store.commit("setSelectedEmployerInfo", this.empl);

        this.refreshMarketplace()
    },
    searchingEmployers(e) {
        if (!this.searchTerm) {
            this.employersCopy = this.employers;
        }

        this.employers = this.employersCopy.filter((data) => {
            if (this.searchTerm) {
                return data.companyName.toLowerCase().includes(this.searchTerm.toLowerCase());
            } else {
                return true
            }
        });

        if(this.searchTerm == '') {
            this.employers = this.allEmployers;
            this.employersCopy = this.allEmployers;
        }
    },
    onVSelectClickOutside (event) {
        // console.log('Clicked outside. Event: ', event)
        this.searchTerm = "";
    },
    focusSearchInput() {
        setTimeout(() => {
            document.getElementById("focusSearch")?.focus();
        }, 100);
    },
    async getDependentInfo(dependentId) {
        const depId = dependentId || this.$store.getters.getDependentId;
        if (depId) {
            if(depId !== this.memberInfo.id) {
                this.$store.dispatch("getDependentInfo", depId);
                this.$store.commit("setDependent", depId);
            } 
        } else if (this.memberInfo) {
            if (this.memberInfo.id && this.memberInfo.subscriberRelation === 'Self') {
                this.$store.commit("setDependent", this.memberInfo.id);
            }
        }
    },
    updateScreenWidth() {
        this.screenWidth = window.innerWidth;

        if (this.screenWidth <= this.mobileBreakpoint && !this.onMobile) {
            window.location.reload();
        } else if (this.screenWidth > this.mobileBreakpoint && this.onMobile) {
            window.location.reload();
        }
    },
    goToMembershipCard() {
      this.$router.push('/membership').then(() => {
        window.location.reload();
      });
    },
    delayCheckmail() {
      // console.log(this.unregisteredEmail);
      clearTimeout(this.typingTimuot);
      this.typingTimuot = setTimeout(() => {
        this.unregisteredEmail = this.unregisteredEmail.trim();
        this.checkEmail();
      }, 500);
    },
    checkEmail() {
      this.unregisteredEmail = this.unregisteredEmail.toLowerCase();

      const isEmailValid = this.emailRules.every(
        (rule) => rule(this.unregisteredEmail) === true
      );
      if (!isEmailValid) {
        this.validEmail = false;
      }
      else {
        this.validEmail = true;
      }
      // console.log("validEmail: ", this.validEmail);
      // console.log("loggedIn: ", this.loggedIn);
    },
    onOutsideClick() {
        console.log(4);
      this.drawer = false;
      // console.log('Clicked outside the drawer. Drawer state:', this.drawer);
    },
    saveEmailAndNotifyNearClinic() {
      if(this.loggedIn) {
        // console.log("Notify if clinic is near your location: get email from memberInfo");
        this.saveEmailNotifyNearClinics(this.memberInfo.email);
      }
      else {
        // console.log("Notify if clinic is near your location: get email from user: ", this.unregisteredEmail);
        if(this.unregisteredEmail) {
          this.saveEmailNotifyNearClinics(this.unregisteredEmail);
        }
        else {
          // console.log("No email was entered");
        }
      }
    },
    saveEmailNotifyNearClinics(email) {
      // Distance Filter
      let newDistance;
      if(this.searchDistance) {
        // console.log("this.searchDistance: ", this.searchDistance);
        if (this.searchDistance == 1) newDistance = 10;
        else if (this.searchDistance == 2) newDistance = 15;
        else if (this.searchDistance == 3) newDistance = 20;
        else if (this.searchDistance == 4) newDistance = 25;
        else if (this.searchDistance == 5) newDistance = 30;
        else if (this.searchDistance == 6) newDistance = 35;
        else if (this.searchDistance == 7) newDistance = 40;
        else if (this.searchDistance == 8) newDistance = 45;
        else if (this.searchDistance == 9) newDistance = 50;
      }
      console.log('this.$route.query', this.$route.query);
      console.log('this.address', this.address);
      // Search params and filters
      let searchFilters = {
        location: this.card2?.coordinates,
        address: this.address,
        searchByName: this.searchByName,
        searchDistance: newDistance,
        serviceTypes: this.pediatrics == true ? ['BH']: [], // BH indicates pediatric service
        selectedSpecialities: this.selectedSpecialities,
        selectedGenders: this.selectedGenders,
        selectedGroupAndOrganizations: this.selectedGroupAndOrganizations,
        query: this.$route.query,
      };
      // If this.$store.getters.getDependentId has ID (Dependent ID), then use it. Else, use the memberInfo.id (Subscriber's ID)
      const memberID = this.$store.getters.getDependentId ? this.$store.getters.getDependentId : this.memberInfo.id;

      // Saving user email param
      let params = {
        email: email,
        member: memberID || null,
        search: searchFilters
      }
      // console.log(params);

      
      api()
        .post(`/member/app/join/wait/list`, params)
        .then((res) => {
          // console.log("Adding Waitlist Success");
          // console.log(res.data);
          this.addToNotifyNearClinic = true; // IF successful
        })
        .catch((err) => {
          console.log("Error while adding email to notify if clinic is near the user's location.");
          console.log(err);
          console.log(err.response.data);
        });

      // Empty unregistered email
      this.unregisteredEmail = "";
    },
    saveWaitlist(email) {
      const memberID = this.$store.getters.getDependentId ? this.$store.getters.getDependentId : this.memberInfo.id;
        
      let params = {
        email: email,
        location: this.selectedLocationForWaitlist,
        member: memberID || null,
        search: {
            location: this.card2?.coordinates,
            address: this.address,
            ...this.$store.getters.getMarketSearchFilters,
        }
      };
      // If this.$store.getters.getDependentId has ID (Dependent ID), then use it. Else, use the memberInfo.id (Subscriber's ID)
      api()
        .post(`/member/app/join/wait/list`, params)
        .then((res) => {
          // console.log("Adding Waitlist Success");
          // console.log(res.data);
          this.addedToWaitlist = true; // IF successful
        })
        .catch((err) => {
          console.log("Adding Waitlist Error");
          console.log(err);
          console.log(err.response.data);
        });
    },
    saveEmailAndNotifyMe() {
        console.log('this.unregisteredEmail', this.unregisteredEmail);
      this.saveWaitlist(this.unregisteredEmail);
      this.notifyMe = false;
    },
    joinWaitlist(providerID) {
      this.selectedLocationForWaitlist = providerID;

      if(!this.loggedIn) { // If NOT logged in
        // console.log("get user email from input");
        this.notifyMe = true;
      }
      else {
        // console.log("get user email fomr memberInfo");
        if(this.memberInfo.email) {
          this.saveWaitlist(this.memberInfo.email);
        }
        else {
          this.noEmail = true;
        }
      }

      // Empty unregistered email
      this.unregisteredEmail = "";
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event);
      console.log(5);
      this.drawer = false;
      // this.searchTerm = "";
    },
    redirect() {
      //Get redirect destination from localStorage
      let redirect = localStorage.getItem("redirect");

      //See where to redirect
      if (redirect == "/home") {
        this.$router.push("/home");
      } else if (redirect == "/account/details") {
        this.$router.push("/account/details");
      } else {
        // console.log("Redirect is empty: ", redirect);
      }
    },
    checkCustomModal() {
      // Hide the current modal first
      this.dependnetAllSetModal = false;

      // console.log("checkCustomModal()");
      // console.log(this.card2);

      api()
        .get(`/member/app/provider/${this.card2.id}/postenrollment`) // this.card.id is teh same as providerId
        .catch((err) => {
          // We should handle error, soon
          // Ex. There was an error while retrieving your post enrollment data.
          // Please contact support, thank you.
          console.log(err);

          // TEMPORARY: If there's an error, go to default function
          this.allSet();
          // this.$swal(err.response.data, "", "error");
          this.somethingWrong = true;
        })
        .then((res) => {
          console.log(res);
          if (res) {
            // if (res.data.postMemberEnrollment !== null) {
            //   this.postMemberEnrollment = res.data.postMemberEnrollment;
            //   this.ifOneMedical = true;
            //   console.log("With Redirect link");
            // } else {
            //   console.log("else 1");
            //   // If custom modal isn't needed to be displayed
            //   this.allSet();
            // }
            if(res.data.postMemberEnrollment !== null) {
              // If selected provider has Custom Link
              this.postMemberEnrollment = res.data.postMemberEnrollment;
              // console.log("With Redirect link");

              if(this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id){
                // Selecting provider for dependents
                this.dependnetAllSetModal = true; // So user can be redirected to Membership Card page
              }
              else if(this.$store.getters.getDependentId == this.memberInfo.id && this.loggedIn) {
                // Selecting provider for current account regardless if dependent or subscriber account
                // this.ifOneMedical = true; // Subscriber's Modal
                this.subscriberAllSetModal = true; // So user can be redirected to Membership Card page
              }
              else { 
                // Selecting provider for self 
                // this.ifOneMedical = true; // Subscriber's Modal 
                this.subscriberAllSetModal = true; // So user can be redirected to Membership Card page
              }
            }
            else {
              // console.log("else 1");
              // If custom modal isn't needed to be displayed
              if(this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id){ 
                // Selecting provider for dependents 
                this.dependnetAllSetModal = true; // So user can be redirected to Membership Card page
              } 
              else if(this.$store.getters.getDependentId == this.memberInfo.id && this.loggedIn) {
                // Selecting provider for current account regardless if dependent or subscriber account
                this.subscriberAllSetModal = true;  // Subscriber's Modal
              }
              else {
                // Selecting provider for self
                this.subscriberAllSetModal = true;  // Subscriber's Modal
              }
              
            }
          } else {
            // console.log("else 2");
            // If custom modal isn't needed to be displayed
            this.allSet();
          }
        });

      // From here, we should detect if user is enrolling their members to different providers
      // if((this.userInfo && this.userInfo.providerId != this.card.id) || (this.userInfo && this.userInfo.locationId != this.card.locationId)){

      // }
      // else {
      //   console.log('else 3');
      //   this.allSet();
      // }
    },
    reloadMarketpalce() {
      this.$router.replace("/marketplace");
      window.location.reload(); 
    },
    allSet() {
      // this.dependnetAllSetModal = false;
      this.$store.commit("setDependent", "");
      this.$router.push("/account/details");
      // this.$router.push('/account/details').then(() => {
      //   // Reload the page after the navigation is complete
      //   // This will make the sidebar appear
      //   // window.location.reload();
      // });
    },
    addDependentAfterEnroll() {
      this.$router.push("/add-family-member");
    },
    confirmEligibility() {
      this.$store.dispatch("getEmployerPlans");
      this.$router.push("/confirm-plan-information");
    },
    getUserInfo(data) {
      if (this.$store.getters.getDependentId) {
        api()
          .get(`/member/app/family/member/${this.dependentId}`)
          .then((res) => {
            if (res) {
              this.userInfo = res.data;
            }
          })
          .catch((err) => {
            console.log("getUserInfo() Error: ", err);
          });
      } else {
        this.userInfo = data;
      }
    },
    generateChangeProviderTitle(status) {
        const firstLetter = this.memberInfo?.subscription?.status?.charAt(0).toLowerCase();

        const indefiniteArticle = ['a', 'e', 'i', 'o', 'u'].includes(firstLetter) ? 'an' : 'a';

        return `You${this.dependentInfo && this.dependentInfo.nextStep ? 'r dependent' : ''} have ${indefiniteArticle} ${status} membership with`
    },
    async selectLocation(dpcID, card, entity) {
      if(!this.selectedEmployerInfo) {
        // Require Employer
        this.drawer = false // Hide filter
        this.needEmployer = true
      }
      else {
        this.selectedLocationToChange = card;

        if (this.loggedIn) {
             if(this.dependentInfo && this.dependentInfo.nextStep) { // If dependent
                if (this.dependentInfo?.subscription?.status == 'active') {
                    this.cannotEnrollModal = true
                } else if (this.dependentInfo?.subscription?.status == 'pending') {
                    this.changeProviderModal = true;
                    this.selectedLocationToChange = {
                        ...this.selectedLocationToChange,
                        title: this.generateChangeProviderTitle(this.dependentInfo?.subscription?.status),
                        oldLocation: this.dependentInfo?.location?.name,
                        isDependent: true
                    };
                } else {
                    this.goToRegister(dpcID, card, entity)
                }
            } else { // Else if subscriber
                if (this.memberInfo?.subscription?.status == 'active') {
                    this.cannotEnrollModal = true;
                } else if (this.memberInfo?.subscription?.status == 'pending' && this.memberInfo?.nextStep !== "selectDPC") {
                    this.changeProviderModal = true
                    this.selectedLocationToChange = {
                        ...this.selectedLocationToChange,
                        title: this.generateChangeProviderTitle(this.memberInfo?.subscription?.status),
                        oldLocation: this.memberInfo?.location?.name
                    };
                } else {
                    this.goToRegister(dpcID, card, entity)
                }
            }
        } else {
            this.goToRegister(dpcID, card, entity)
        }
      }
    },
    updateProvider() {
        this.selectingProvider = true; 
        this.changeProviderModal = false;

        let data = {
            providerId: this.selectedLocationToChange.id,
            locationId: this.selectedLocationToChange.locationId,
        };

        console.log('this.dependentInfo', this.dependentInfo);

        if(!this.dependentInfo && !this.dependentInfo.nextStep) { // If this.$store.getters.getDependentId is the same as subscriber ID
            let params = "";
            if (this.selectedLocationToChange.locationId) {
                params = `?locationId=${this.selectedLocationToChange.locationId}`;
            }
            
            api()
                .get(`/member/app/market/provider/${this.selectedLocationToChange.id}` + params)
                .then((res) => {
                    if (res) {
                        this.provider = res.data;
                        this.$store.commit("setSelectedProvider", this.provider);
                        const dependentId = this.$store.getters.getDependentId;

                        // Selecting provider for dependent
                        if (this.$store.getters.getDependentId) {
                            api()
                                .post(
                                `/member/app/family/member/${this.$store.getters.getDependentId}/set/provider`,
                                data
                                )
                                .catch((err) => {
                                    if (err) {
                                        console.log(
                                        "Dependent Enrollment Error : ",
                                        err.response.data
                                        );
                                        this.errorMessage = err.response.data;
                                        this.selectingProvider = false;
                                        this.dependnetErrorModal = true;

                                        // Empty Dependent ID from Store
                                        this.$store.commit("setDependent", "");
                                    }
                                })
                                .then(async (res) => {
                                    if (res) {
                                        // this.dependnetAllSetModal = true;

                                        // Empty Dependent ID from Store
                                        this.$store.commit("setDependent", "");

                                        //Dispatch getMemberInfo
                                        this.$store.dispatch("getDependentInfo", dependentId);
                                        await this.$store.dispatch("asyncGetMemberInfo");
                                        this.getDependentInfo(dependentId)

                                        this.selectingProvider = false;
                                        this.changedProviderModal = true;
                                    }
                                });
                        }
                    }
            });
        } else {
            api()
                .post("/member/app/set/provider", data)
                .catch((err) => {
                if (err) {
                    // console.log("Error", err.response.data);
                    this.errorMessage = err.response.data;
                    this.selectingProvider = false;
                    this.dependnetErrorModal = true;
                }
                })
                .then((res) => {
                if (res) {
                    //Dispatch getMemberInfo
                    this.$store.dispatch("getMemberInfo");
                    this.$store.dispatch('getEmployerPlans');

                    this.selectingProvider = false;
                    this.changedProviderModal = true;
                }
                });
           
        }
    },
    goToRegister(dpcID, card, entity) {
      // Selecting provider loader
      this.selectingProvider = true; // Remove https://apalyhealth.atlassian.net/browse/MEM-619

      // See if logged in
      if (this.loggedIn) {
        if (entity != "location") {
          this.goToDetails(card, entity);
        } else {
          try {
            // Mixpanel Integrations
            let tracking_user_id;
            if(this.memberInfo.id) {  // If logged in
                tracking_user_id = this.memberInfo
            }
            else {
                tracking_user_id = null;
            }
            // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
            const selecting_provider_timestamp = new Date().toISOString();
            // Track Selected Provider
            Mixpanel.track("member_clinic_selected", {
                user_id: tracking_user_id, 
                timestamp: selecting_provider_timestamp,
                clinic_selected: card,
            });

            if (!this.$store.getters.getDependentId) {
                    // Set dependent id
                    if (this.memberInfo.id && this.memberInfo.subscriberRelation === 'Self') {
                        this.$store.commit("setDependent", this.memberInfo.id);
                    } else if (this.memberInfo.id && this.memberInfo.associatedDependents[0]) {
                        this.$store.commit("setDependent", this.memberInfo.associatedDependents[0]);
                    }
            }

            // Selecting a provider for the currently logged in account regardless if Self or Dependent
            if (this.memberInfo?.nextStep != 'complete') {
                // Sometimes, this.$store.getters.getDependentId is being used to select provider for subscriber
                // If subscriber is being resgistered by dependent as Self
                // So we must also consider the condition
                if(this.$store.getters.getDependentId && this.$store.getters.getDependentId == this.memberInfo.id) { // If this.$store.getters.getDependentId is the same as subscriber ID
                    this.selectProviderForSubscriber(dpcID, card, entity);
                }
                else { // Else, it must be a dependent
                    this.selectProviderForDependent(dpcID, card, entity);
                }
            }
            else if (this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id && this.loggedIn) {
                // For Dependents enrollment ONLY
                this.selectProviderForDependent(dpcID, card, entity);
            }
            else if(this.memberInfo?.nextStep == 'complete') {
                this.selectingProvider = false; 
                this.cannotEnrollModal = true;
            }
          } catch (error) {
                this.selectingProvider = false;
                console.log('goToRegister error', error);
          }
        }
      } else if (!this.loggedIn) {
        // No account should be logged in for this function
        let params = "";
        if (card.locationId) {
          params = `?locationId=${card.locationId}`;
        }
        api()
          .get(`/member/app/market/provider/${dpcID}` + params)
          .then((res) => {
            if (res) {
              this.provider = res.data;
              this.$store.commit("setSelectedProvider", this.provider);
              if (
                this.userInfo &&
                this.userInfo.providerId != null &&
                this.userInfo.locationId != null &&
                false
              ) {
                // TODO: Why do we do this?
                this.cannotEnrollModal = true;
              } else if (
                this.userInfo &&
                (this.userInfo.nextStep == "confirmPlan" ||
                  this.userInfo.setup == "incomplete")
              ) {
                this.$store.dispatch("getEmployerPlans");
                this.$router.push("/confirm-plan-information");
              } else if (
                this.userInfo &&
                this.userInfo.nextStep == "selectDPC"
              ) {
                let data = {
                  providerId: card.id,
                  locationId: card.locationId,
                };
                if (this.$store.getters.getDependentId) {
                  api()
                    .post(
                      `/member/app/family/member/${this.$store.getters.getDependentId}/set/provider`,
                      data
                    )
                    .catch((err) => {
                      if (err) {
                        console.log("errpr", err);
                        this.dependnetErrorModal = true;
                        this.selectingProvider = false;
                      }
                    })
                    .then((res) => {
                      if (res) {
                        this.dependnetAllSetModal = true;
                        this.selectingProvider = false;
                      }
                    });
                } else {
                  api()
                    .post("/member/app/set/provider", data)
                    .catch((err) => {
                      if (err) {
                        console.log("errpr", err);
                      }
                    })
                    .then((res) => {
                      if (res) {
                        this.subscriberAllSetModal = true;
                        this.selectingProvider = false;
                      }
                    });
                }
              } else {
                this.$router.push("/register");
              }
            }
          });
      }
    },
    selectProviderForDependent(dpcID, card, entity) {
      // console.log("Select Provider for Dependent");
      // console.log("Dependent ID: ", this.$store.getters.getDependentId);
      // console.log("DPC ID: ", dpcID);
      // console.log("card", card);
      // console.log("entity", entity);

      // memberInfo IS NOT RELEVANT HERE because this is for selecting a provider for a dependent

      let params = "";
      if (card.locationId) {
        params = `?locationId=${card.locationId}`;
      }
      api()
        .get(`/member/app/market/provider/${dpcID}` + params)
        .then((res) => {
          if (res) {
            this.provider = res.data;
            this.$store.commit("setSelectedProvider", this.provider);
            let data = {
              providerId: card.id,
              locationId: card.locationId,
            };
            // Seleciting provider for dependent
            if (this.$store.getters.getDependentId) {
              api()
                .post(
                  `/member/app/family/member/${this.$store.getters.getDependentId}/set/provider`,
                  data
                )
                .catch((err) => {
                  if (err) {
                    console.log(
                      "Dependent Enrollment Error : ",
                      err.response.data
                    );
                    this.errorMessage = err.response.data;
                    this.selectingProvider = false;
                    this.dependnetErrorModal = true;

                    // Empty Dependent ID from Store
                    this.$store.commit("setDependent", "");
                  }
                })
                .then((res) => {
                  if (res) {
                    this.selectingProvider = false;
                    // this.dependnetAllSetModal = true;

                    // Check if select provider has Custom Link
                    this.checkCustomModal();

                    // Empty Dependent ID from Store
                    this.$store.commit("setDependent", "");

                    //Dispatch getMemberInfo
                    this.$store.dispatch("getMemberInfo");
                  }
                });
            }
            // If no dependent ID was found
            else {
              // console.log('Dependent Error 2: ', err.response.data)
              this.errorMessage = "No Dependent ID was found";
              this.selectingProvider = false;
              this.dependnetErrorModal = true;
            }
          } else {
            this.errorMessage = "Provider not found";
            this.selectingProvider = false;
            this.dependnetErrorModal = true;
          }
        });
    },
    selectProviderForSubscriber(dpcID, card, entity) {
      // console.log("Select Provider for Subscriber");
      // console.log("memberInfo: ", this.memberInfo);
      // console.log("Dependent ID: ", this.$store.getters.getDependentId);
      // console.log("DPC ID: ", dpcID);
      // console.log("card", card);
      // console.log("entity", entity);

      // This is when subscriber failed the eligibility test before so they are already logged in for this
      if (this.memberInfo) {
        // If user isn't filling for a dependent and eligibility didn't failed the last time
        if (this.$store.getters.getDependentId && this.memberInfo.setup == "incomplete" ) {
          let data = {
            providerId: card.id,
            locationId: card.locationId,
          };
          api()
            .post("/member/app/set/provider", data)
            .catch((err) => {
              if (err) {
                // console.log("Error", err.response.data);
                this.errorMessage = err.response.data;
                this.selectingProvider = false;
                this.dependnetErrorModal = true;
              }
            })
            .then(async (res) => {
              if (res) {
                //Dispatch getMemberInfo
                await this.$store.dispatch("asyncGetMemberInfo");

                // If user's eligibility confirmation isn't complete,
                // then user must be able to confirm their eligibility, again.
                // This logic will also serve the first time eligibility check
                if ( this.memberInfo && (this.memberInfo.nextStep == "confirmPlan") ) {
                  this.selectingProvider = false;
                  this.$store.dispatch('getEmployerPlans');
                  // this.$router.push('/confirm-plan-information');   
                  this.$router.push('/confirm-plan-information').then(() => {
                    window.location.reload();
                  });
                }
                // If user's eligibility confirmation is complete,
                // then we show this modal
                else {
                  this.selectingProvider = false;
                  this.subscriberAllSetModal = true;
                }
              }
            });
          return;
        }
        // else if eligibility is successful, then user shouldn't be able to enroll to another provider
        else if (
          !this.$store.getters.getDependentId &&
          this.memberInfo.setup != "incomplete"
        ) {
          this.selectingProvider = false;
          this.cannotEnrollModal = true;
          // return
        } else {
          // console.log(
          //   `Selecting for ${this.memberInfo.firstName} ${this.memberInfo.lastName}'s provider failed because the flow went to Subscriber's flow.`
          // );
          this.errorMessage = `Something went wrong while selecting for ${this.memberInfo.firstName} ${this.memberInfo.lastName}'s provider.`;
          this.selectingProvider = false;
          this.dependnetErrorModal = true;
        }
      }
    },
    goToDetails(item, entity) {
      if(!this.selectedEmployerInfo) {
        // Require Employer
        this.drawer = false // Hide filter
        this.needEmployer = true
      }
      else {
        if (entity == "solution") {
          this.$router.push(`/marketplace/solutions/details/${item.id}`);
        } else if (entity == "location") {
          this.$store.state.documentTitle = item.name;
          this.$router.push({
            path: `/marketplace/provider/details/${item.id}`,
            query: { location: item.locationId },
          });
        } else if (entity == "vendor") {
          this.$router.push(`/marketplace/vendor/details/${item.id}`);
        } else if (entity == "provider") {
          this.$router.push(`/marketplace/clinician/details/${item.id}`);
        }
        this.showOverlay = false;
      }
    },
    checkNoData(event) {
      const inputText = event.target.value;
      if (inputText.trim() !== "") {
        const matchedEmployer = this.employers.find(
          (emp) =>
            emp.companyName.toLowerCase() === inputText.toLowerCase() ||
            emp.companyName.toLowerCase().startsWith(inputText.toLowerCase())
        );
        if (matchedEmployer) {
          this.employer = matchedEmployer;
        } else {
          this.employer = {
            companyName: inputText,
            status: "inactive",
          };
        }
        setTimeout(() => {
          document.querySelector(".v-menu__content").style.display = "none";
        }, 100);
      }
    },
    setEmployer(item) {
      this.employer = item;
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            // this.userCenter = userPosition;
            this.$store.commit("setUserLocation", userPosition);
            // this.openCard(userPosition);
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported.");
      }
    },
    setUpGoogleMaps() {
      const inputElement = this.$refs[`pac-input`].$el.querySelector("input");

      this.autocomplete = new google.maps.places.Autocomplete(
        inputElement,
        { types: ["geocode"] }
      );

      
      this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    },
    handlePlaceChanged() {
      const place = this.autocomplete.getPlace();

      if (place && place.formatted_address) {
        const selectedAddress = place.formatted_address;
        this.address = selectedAddress;
      }

      this.$store.commit("setAddressSeached", this.address);
      this.$store.commit("setSearchBy", "filters");
      this.$store.commit("setSearchAddress", this.address);
      this.centerMapByAddress(this.address);

      if(!this.selectedEmployerInfo) {
        // Require Employer
        this.needEmployer = true
      }
      else {
        if (this.pageNumber == 1) this.refreshMarketplace();
        else this.pageNumber = 1;
      }
    },
    openUserCard(userCenter) {
      let update =
        parseFloat(userCenter.lng) != this.center.lng ||
        parseFloat(userCenter.lat) != this.center.lat;
      if (update) this.$store.commit("setMapCenter", userCenter);
      else this.$refs.gmap.panTo(this.center);
    },
    // customMapPin(place) {
    //   // if(this.marketplace?.length !== 0) {
    //   //   console.log("===========================================");
    //   //   console.log("M: ", this.marketplace);
    //   // }
    //   // console.log("MLat: ", this.marketplace?.coordinates?.lat);
    //   // console.log("MLng: ", this.marketplace?.coordinates?.lng);
    //   // console.log("PLat: ", place?.coordinates?.lat);
    //   // console.log("PLng: ", place?.coordinates?.lng);
    //   // var MLat = this.marketplace?.coordinates?.lat ? parseInt(this.marketplace?.coordinates?.lat) : null; 
    //   // var PLat = parseInt(place?.coordinates?.lat);
    //   // var MLng = this.marketplace?.coordinates?.lng ? parseInt(this.marketplace?.coordinates?.lng) : null; 
    //   // var PLng = parseInt(place?.coordinates?.lng);
    //   // console.log("===========================================");
    //   // console.log("MLng: ", MLng);
    //   // console.log("PLng: ", PLng);
    //   // console.log("Place: ", place?.coordinates?.lat +" - "+ place?.coordinates?.lng);
    //   for (const places of place) {
    //     var PLat = parseInt(places?.coordinates?.lat);
    //     var PLng = parseInt(places?.coordinates?.lng);
    //     // var MLat = this.marketplace?.coordinates?.lat ? parseInt(this.marketplace?.coordinates?.lat) : null; 
    //     // var MLng = this.marketplace?.coordinates?.lng ? parseInt(this.marketplace?.coordinates?.lng) : null; 

    //     const marketplace = this.marketplace.coordinates;
        
    //     // Find the family member with matching ID
    //     const matchingPlaces = marketplace.find(loc => parseInt(loc.lat) == PLat && parseInt(loc.lng) == PLng);

    //     console.log("matchingPlaces: ", matchingPlaces);
    //   }
    //   // if(MLat == PLat && MLng == PLng) {
    //   //   console.log("Marketplace:", marketplace);
    //   // }
    //   // Match the placeId with filtred l
    //   return '/dark-blue.png'
    // },
    openCard(place) {
        if (place.isVirtualLocation) {
            this.$store.commit("setMapCenter", place.coordinates);
            return;
        }
      let update =
        parseFloat(place.coordinates.lng) != this.center.lng ||
        parseFloat(place.coordinates.lat) != this.center.lat;
      if (update) this.$store.commit("setMapCenter", place.coordinates);
      else this.$refs.gmap.panTo(this.center);
      // return

      // let coordinates = place.coordinates ? place.coordinates : place
      // update the map Center
      // console.log("coordinates: ",  coordinates);
      // this.$store.commit("setMapCenter", coordinates);
      this.infoWindowPosition = place.coordinates;
      this.infoWindowOpened = true;
      this.cardLoaded = true;

      // alert("loader");
      // if (place.coordinates) {
      //   this.infoWindowPosition = place.coordinates;
      // } else {
      //   this.infoWindowPosition = place;
      // }
      // this.infoWindowContent = place;
      // if (place.coordinates) {
      //   this.card = place;
      // } else {
      //   this.card = null;
      // }
      api()
        .get(`/member/app/market/location/${place.id}/preview`)
        .then((res) => {
          if (res && res.data) {
            this.cardLoaded = false;
            this.infoWindowContent = res.data;
            this.card2 = this.infoWindowContent; // User card2
            // console.log("card2", this.card2);

            // Mobile Function
            if (window.innerWidth < 700) {
              this.$router.push({
                path: `/marketplace/provider/details/${this.card2.id}`,
                query: { location: this.card2.locationId },
              });
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          // this.$swal(err.response.data, "", "error");
          this.somethingWrong = true;
        });
      // this.infoWindowOpened = true;
    },
    closeInfoWindow() {
      this.infoWindowOpened = false;
      this.selectedPlace = null;
    },
    goToPage(pageNumber) {
      this.pageNumber = pageNumber;
      if (this.address != "") {
        this.searchByZip();
      } else {
        //  this.getMarketplace();
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    resolveMarketplaceBackgroundColor(card) {
      if (card) {
        if (card.entity == "location") {
          return "border-left: 15px solid #172E4D; border-radius: 8px 0 0 8px";
        } else {
          return "border-left: 15px solid #249999; border-radius: 8px 0 0 8px";
        }
      }
    },
    // goToDetails(item, entity) {
    //   if (entity == "solution") {
    //     this.$router.push(`/marketplace/solutions/details/${item.id}`);
    //   } else if (entity == "location") {
    //     this.$store.state.documentTitle = item.name;
    //     this.$router.push({
    //       path: `/marketplace/provider/details/${item.id}`,
    //       query: { location: item.locationId },
    //     });
    //   } else if (entity == "vendor") {
    //     this.$router.push(`/marketplace/vendor/details/${item.id}`);
    //   } else if (entity == "provider") {
    //     // this.$router.push(`/marketplace/clinician/details/${item.id}`);
    //   }
    //   this.showOverlay = false;
    // },
    returnClinicanName(inputString) {
      if (inputString) {
        const words = inputString.split(" ");
        const capitalizedWords = words.map((word) => {
          if (word.length === 0) return word;
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
      }
    },
    returnCardImage(card) {
      if (card) {
        if (card.logo) {
          return card.logo;
        } else {
          if (card.entity == "location") {
            return "/tpa/clinicsAndOrganizations.svg";
          } else if (card.entity == "vendor") {
            return "/tpa/pointSolutionVendor.svg";
          } else if (card.entity == "provider") {
            if (card.gender == "F") {
              return "/svg/clinicianCard.svg";
            } else if (card.gender == "M") {
              return "/svg/clinicianCardMale.svg";
            }
          }
        }
      }
    },
    getChipColor(index) {
      return index + this.visiblePages[0] === this.pageNumber ? "#194680" : "";
    },
    getTextColor(index) {
      return this.getChipColor(index) === "#194680" ? "white" : "";
    },

    nextPage() {
      // return console.log('nextPage',this.pageNumber)
      this.pageNumber++;
      if (this.address != "") {
        this.searchByZip();
      } else {
        //  this.getMarketplace();
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    prevPage() {
      // return console.log('prevPage',this.pageNumber)
      if (this.pageNumber > 1) {
        this.pageNumber--;
        if (this.address != "") {
          this.searchByZip();
        } else {
          //  this.getMarketplace();
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    setPage(page) {
      if (this.$refs.allMarketplaceItemsRef) {
        this.$refs.allMarketplaceItemsRef.$el.scrollIntoView({
          top: 0,
          behavior: "smooth",
        });
      }
      return; // we handle this on watch
      let nextPage = page ? page : this.pageNumber;
      this.pageNumber = page; // Update the local component state
      this.$store.commit("setPage", nextPage);
      console.log("page changed");
      // return console.log('setPage',this.pageNumber)
      // this.$vuetify.goTo(0)

      switch (this.searchBy) {
        case "address":
          return this.searchByZip();
        case "filter":
          return this.searchResults();
        default:
          return this.getMarketplace();
      }
    },
    openDrawer() {
        console.log(6);
      this.drawer = !this.drawer;

      console.log('this.drawer', this.drawer);
      
      // Hide chatbot
      document.getElementById("zsiq_float").style.display = 'none';
    },
    changeTab(tab) {
      // this.pageNumber = 1;
      // if(this.address == ''){
      //  this.getMarketplace();
      // }
      this.tab = tab;
      this.$store.commit("setTab", tab);
      if (tab == "all") {
        // this.$store.state.tabName = tab; // Can't set store variables without a commit
        // this.slicedMarketplace = this.all;
        // this.setPage(1) // No reset because it's BE paginated
        this.tabTitle = "Direct Primary Care";
        // this.$store.state.totalPages = Math.ceil(this.clinics.length/6) || 0
        this.pageNumber = this.lastPageNumber;
      } else if (tab == "clinics") {
        // this.$store.state.tabName = tab;
        // this.setPage(1) // No reset because it's BE paginated
        // this.slicedMarketplace = this.clinics;
        // this.$store.state.totalPages = Math.ceil(this.clinics.length/6) || 0
        this.tabTitle = "";
        this.pageNumber = this.lastPageNumber;
      } else if (tab == "clinicians") {
        // this.$store.state.tabName = tab;
        // this.slicedMarketplace = this.clinicians;
        // this.setPage(1)
        //reseting total number of pages because the logic is different now- temporary solution for now -J.V.
        // this.$store.state.totalPages = Math.ceil(this.clinicians.length/6) || 0 // This is calc by BE
        this.tabTitle = "";
        this.pageNumber = 1;
      }
    },
    changeSolutions(tab) {
      this.solutions = tab;
    },
    async getMarketplace() {
        let newDistance;
        if(this.searchDistance) {
            if (this.searchDistance == 1) newDistance = 10;
            else if (this.searchDistance == 2) newDistance = 15;
            else if (this.searchDistance == 3) newDistance = 20;
            else if (this.searchDistance == 4) newDistance = 25;
            else if (this.searchDistance == 5) newDistance = 30;
            else if (this.searchDistance == 6) newDistance = 35;
            else if (this.searchDistance == 7) newDistance = 40;
            else if (this.searchDistance == 8) newDistance = 45;
            else if (this.searchDistance == 9) newDistance = 50;
        }
        
        const metadata = {
            searchByZip: this.extractZipCode(this.address),
            sessionId: `anon_${Date.now()}`,
            member: this.memberInfo.id ? this.memberInfo.id : null,
            address_searched: this.address,
            employer_name: this.empl?.companyName,
            distance_search: newDistance,
            email: this.memberInfo.email ? this.memberInfo.email : null,
            filters: this.convertFiltersToStringArray({
                searchDistance: newDistance,
                selectedSpecialities: this.selectedSpecialities,
                selectedGenders: this.selectedGenders,
                selectedGroupAndOrganizations: this.selectedGroupAndOrganizations,
            })
        }

      // this.places = []; // T.N. we don't reset pins that we got initially
      this.didFilter = false;
      this.showMap = true;
      const getProviders = await this.$store.dispatch("getMarketplace", {
        query: {
            ...this.$route.query,
            userZipCode: this.userZipcode,
        },
        metadata
      });

    //   Redirect to login when undefined
      if (getProviders && getProviders === 401) {
        this.$router.push(`/`);
      }
      return;
      // const existingParamsString = Object.keys(this.$route.query)
      //   .map((key) => `${key}=${encodeURIComponent(this.$route.query[key])}`)
      //   .join("&");
      // // this.loader = true;
      // this.noResultsFromSearch = false;
      // const hasExistingParams = existingParamsString !== "";
      // let url = `/member/app/${
      //   this.dependentId ? "family/member/" + this.dependentId + "/" : ""
      // }market`;
      // url += hasExistingParams ? `?${existingParamsString}` : "";
      // console.log("url now", url);
      // // url += hasExistingParams ? "&" : "";
      // url += `${url.includes("?") ? `&` : `?`}page=${this.pageNumber}&limit=${
      //   this.itemPerPage
      // }`;
      // if (this.userCenter && this.userCenter.lat && this.userCenter.lng)
      //   url += `&lng=${this.userCenter.lng}&lat=${this.userCenter.lat}`;
      // // T.N. Refactored as above
      // // if (this.userCenter != null) {
      // //   url += `?page=${this.pageNumber}&limit=${this.itemPerPage}&lat=${this.userCenter.lat}&lng=${this.userCenter.lng}`;
      // // } else {
      // //   url += `?page=${this.pageNumber}&limit=${this.itemPerPage}`;
      // // }
      // api()
      //   .get(url)
      //   .then((res) => {
      //     if (res) {
      //       // this.marketplace = [...res.data.clinics, ...res.data.clinicians];
      //       // this.items = this.marketplace;
      //       // if (res.data.nextPage !== null && res.data.totalPages > 1) {
      //       //   this.hasNextPage = true;
      //       // }
      //       // if (res.data.totalPages) {
      //       //   this.totalPages = res.data.totalPages;
      //       // }
      //       this.totalPages = res.data.totalPages || 0;

      //       let marketplace = [];
      //       if (this.tab == "clinics") {
      //         // this.marketplace = res.data.clinics
      //         marketplace = res.data.clinics;
      //       } else if (this.tab == "clinicians") {
      //         // this.marketplace = res.data.clinicians
      //         marketplace = res.data.clinicians;
      //       } else {
      //         marketplace = [...res.data.clinics, ...res.data.clinicians];
      //       }

      //       this.clinicians = res.data.clinicians;
      //       this.clinics = res.data.clinics;
      //       // this.loader = false;

      //       // T.N. No longer needed. Pins are populated only once
      //       // this.marketplace.forEach((item) => {
      //       //  if(item.entity == 'location'){
      //       //   item.coordinates.lat = parseFloat(item.coordinates.lat);
      //       //   item.coordinates.lng = parseFloat(item.coordinates.lng);
      //       //   this.places.push(item)
      //       //  }
      //       // });
      //       // this.center = this.places[0].coordinates; // T.N. See refactored in searchResults
      //       if (res.data.clinics[0])
      //         this.centerPin(res.data.clinics[0].locationId);

      //       // this.searchBy = 'normal'
      //       this.$store.commit("setSearchHistory", {
      //         searchBy: "normal",
      //         marketplace,
      //       });
      //       if (
      //         this.clinics.length < 1 &&
      //         this.userCenter &&
      //         this.userCenter.coordinates
      //       )
      //         this.center = this.userCenter;
      //     }
      //   })
      //   .catch((err) => {
      //     if (err) {
      //       // this.loader = false;
      //     }
      //   })
      //   .finally(() => {
      //     this.loader = false;
      //   });
    },
    isZipCode(str) {
        const zipRegex = /^\d{5}(-\d{4})?$/;
        return zipRegex.test(str);
    },
    extractZipCode(address) {
        if (address) {
            const zipCodeRegex = /\b\d{5}\b/;
            const match = address.match(zipCodeRegex);
            return match ? match[0] : null;
        }
        return null
    },
    searchByBounds() {
      console.log("Search by bounds")
      if(!this.selectedEmployerInfo) {
        // Require Employer
        this.needEmployer = true;
      }
      else this.searchResults(true) // Pass boolean argument
    },
    getAllPlaces() {
      // this.places = [];
      this.showMap = true;
      // If custom DPC link
      if(this.$route.query.company) {
        this.$store.dispatch("getAllPlaces", { alias: this.$route.query.company });
      }
      else {
        this.$store.dispatch("getAllPlaces");
      }

      // return;
      // api()
      //   // .get('/member/app/market')
      //   .get("/member/app/market/location/pins")
      //   .then((res) => {
      //     if (res && res.data) {
      //       // res.data.clinics.forEach((item) => {
      //       //  if(item.entity == 'location'){
      //       //   item.coordinates.lat = parseFloat(item.coordinates.lat);
      //       //   item.coordinates.lng = parseFloat(item.coordinates.lng);
      //       //   this.places.push(item)
      //       //  }
      //       // });
      //       this.places = res.data; // see issue #542 in API
      //     }
      //   })
      //   .catch((err) => {
      //     if (err) {
      //       // this.loader = false;
      //     }
      //   });
    },
    centerPin(id) {
      const matchPin = this.places.find((item) => item.id == id);
      // if(matchPin)this.center = matchPin.coordinates
      this.$store.commit("setMapCenter", matchPin.coordinates);
    },
    removeCompanyFromUrl() {
      this.urlCompany = null;
      this.urlLocation = null;
      this.$router.push("/marketplace");
    },
    searchByNameChange() {
      if(this.searchByName == "") {
        this.didFilter = false;
      }
    },
    removeSearchedName() {
      this.searchByName = "";
      this.refreshMarketplace();
    },
    removeNewPatients() {
      this.newPatients = false;
      // this.$router.push("/marketplace");
      this.refreshMarketplace();
    },
    removePediatricService() {
      this.pediatrics = false;
      // this.$router.push("/marketplace");
      this.refreshMarketplace();
    },
    removeChip(chip, selectedArray) {
      // if(this.$route.query.company && this.$route.query.company == this.aliasUrl){
      //   //nothing
      // }
      // else{
      // console.log("chip ", chip);
      // console.log("selectedArray", selectedArray);
      // console.log("companyFromUrl", this.companyFromUrl);

      const filterArray = selectedArray;
      const chipIndex = filterArray.indexOf(chip);
      if (chipIndex !== -1) {
        filterArray.splice(chipIndex, 1);
      }

      // No need to change route.path
      // const queryParams = { ...this.$route.query };
      // delete queryParams.company;
      // this.$router.replace({
      //   path: this.$route.path,
      //   query: queryParams,
      // });

      // this.refreshMarketplace()
      this.searchResults();

      this.drawer = false;

      // No need for this.
      // if (chip == this.companyFromUrl) {
      //   const urlWithoutCompany = window.location.origin + window.location.pathname;
      //   window.history.replaceState({}, document.title, urlWithoutCompany);
      // }

      // }
    },

    // Added by T.N.
    refreshMarketplace() {
      this.needEmployer = false; // Set to false

      // This will refresh marketplace: 1) reset pagination, 2) make api request based on search type

      if (this.tab == "clinicians") this.pageNumber = 1; // because we still use FE pagination for that
      this.$store.commit("setPage", 1);
      switch (this.searchBy) {
        // case 'address': this.searchByZip(); break;
        case "normal":
          this.getMarketplace();
          break;
        default:
          this.searchResults();
      }
    },

    searchAddress(event, bypass) {
      // console.log('searchaddress input',this.address)
      // although set, we still make these 2 commits,

      if (this.$refs['employerSelect']) {
        this.$refs['employerSelect'].focus();
      }

      // bypass -> prevent updating the clinics when search button is clicked and missing employer and address

      if (!bypass && (!this.selectedEmployerInfo || !this.address)) {

        if(!this.selectedEmployerInfo) {
          // Require Employer
          this.needEmployer = true;
        }
        return;
      }

      this.needEmployer = false; // Set to False by default

      this.$store.commit("setSearchBy", "filters");
      this.$store.commit("setPage", 1);
      this.pageNumber = 1;
      this.$store.commit("setSearchAddress", this.address);
      this.$store.commit("setAddressSeached", this.address);
      this.refreshMarketplace();

      // Every other case is handles by the google listener: handlePlaceChanged
      // ----------------------------------------------------------------------//

      // this.$store.commit('setPage',1)
      // TODO: Temporary solution beacuse google api takes some time from click the suggestion to updating the input's val
      // setTimeout(() => {
      //   this.centerMapByAddress(this.address)
      //   this.searchByZip();
      // }, 600)
      // this.pageNumber = 1
      // this.centerMapByAddress(this.address)
      // if(this.address != '')this.centerMapByAddress(this.address)
      // else this.$store.commit('setMapCenter', this.userCenter)

      // this.searchByZip() // This is called in the timeout function in order to wait for google to update this.address

      // STORE SEARCHED LOCATION IN LOCAL STORAGE
      localStorage.setItem("searchedAddress", this.address);
    },
    extractZipCode(address) {
        const zipCodeRegex = /\b\d{5}\b/;
        const match = address.match(zipCodeRegex);
        return match ? match[0] : null;
    },
    searchByZip() {
      // SAVE SEARCHED ADDRESS
      // localStorage.setItem('searchedAddress', this.address);
      this.$store.commit("setPage", 1);
      this.$store.commit("setSearchAddress", this.address);

      // this.places = []; // T.N. we don't clear already populated pins
      if (!this.address || this.address == "") {
        this.getMarketplace(); // Don't do anything if string empty
        return;
      }
      this.$store.dispatch("searchMarketplaceByAddress", {
        address: this.address,
        query: this.$route.query,
        filters: {
          selectedCategories: this.selectedCategories,
          selectedGroupAndOrganizations: this.selectedGroupAndOrganizations,
          selectedCredentials: this.selectedCredentials,
          selectedGenders: this.selectedGenders,
          selectedServiceTypeCodes: this.selectedServiceTypeCodes,
        },
      });
      if (this.$refs.allMarketplaceItemsRef) {
        this.$refs.allMarketplaceItemsRef.$el.scrollIntoView({
          top: 0,
          behavior: "smooth",
        });
      }
      return;
      // this.hasNextPage = false;
      // const data = {
      //   search: {
      //     address: this.address,
      //   },
      // };
      // let url = "/member/app/market/search";
      // url += `${url.includes("?") ? `&` : `?`}page=${this.pageNumber}&limit=${
      //   this.itemPerPage
      // }`;
      // if (this.userCenter && this.userCenter.lat && this.userCenter.lng)
      //   url += `&lng=${this.userCenter.lng}&lat=${this.userCenter.lat}`;

      // this.loader = true;
      // api()
      //   .post(url, data)
      //   .then((res) => {
      //     if (res && res.data) {
      //       this.showMap = false;
      //       this.showUser = false;
      //       this.fromSearch = true;
      //       let marketplace = [];
      //       if (this.tab == "all") {
      //         // this.marketplace = [...res.data.clinics, ...res.data.clinicians]
      //         // if(res.data.nextPage && res.data.totalPages > 1)this.hasNextPage = true
      //         marketplace = [...res.data.clinics, ...res.data.clinicians];
      //         this.all = marketplace;
      //       }
      //       // else if(this.tab == 'clinics')this.marketplace = res.data.clinics
      //       // else if(this.tab == 'clinicians')this.marketplace = res.data.clinicians
      //       else if (this.tab == "clinics") marketplace = res.data.clinics;
      //       else if (this.tab == "clinicians")
      //         marketplace = res.data.clinicians;

      //       this.clinics = res.data.clinics;
      //       this.clinicians = res.data.clinicians;

      //       if (res.data.clinics[0])
      //         this.centerPin(res.data.clinics[0].locationId);
      //       this.totalPages = res.data.totalPages || 0;

      //       if (marketplace.length < 1) {
      //         this.noResultsFromSearch = true;
      //       }

      //       // this.searchBy = 'address'
      //       this.$store.commit("setSearchHistory", {
      //         searchBy: "address",
      //         marketplace,
      //       });
      //       if (this.clinics.length < 1) this.centerMapByAddress(this.address);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     // this.$swal(err.response.data, "", "error");
      //     this.somethingWrong = true;
      //   })
      //   .finally(() => {
      //     this.loader = false;
      //   });
      // return;

      // // T.N. See the refactoring above and double check if it's missing any additional logic
      // if (this.address != "") {
      //   let url = "/member/app/market/search";
      //   if (this.$route.query.company) {
      //     url += "?company=" + this.$route.query.company;
      //   }
      //   if (this.userCenter != null) {
      //     url += `?page=${this.pageNumber}&limit=${this.itemPerPage}&lat=${this.userCenter.lat}&lng=${this.userCenter.lng}`;
      //   } else {
      //     url += `?page=${this.pageNumber}&limit=${this.itemPerPage}`;
      //   }
      //   this.loader = true;
      //   api()
      //     .post(url, data)
      //     .catch((err) => {
      //       if (err) {
      //         // this.$refs.error_modal.openPopUp(err);
      //       }
      //     })
      //     .then((res) => {
      //       if (res) {
      //         this.showMap = false;
      //         this.showUser = false;
      //         this.fromSearch = true;
      //         if (this.tab == "all") {
      //           this.marketplace = [
      //             ...res.data.clinics,
      //             ...res.data.clinicians,
      //           ];
      //           if (res.data.nextPage !== null && res.data.totalPages > 1) {
      //             this.hasNextPage = true;
      //           }
      //         } else if (this.tab == "clinics") {
      //           this.marketplace = res.data.clinics;
      //         } else if (this.tab == "clinicians") {
      //           this.marketplace = res.data.clinicians;
      //         }
      //         this.loader = false;
      //         this.marketplace.forEach((item) => {
      //           if (item.entity == "location") {
      //             item.coordinates.lat = parseFloat(item.coordinates.lat);
      //             item.coordinates.lng = parseFloat(item.coordinates.lng);
      //             this.places.push(item);
      //           }
      //         });
      //         if (this.fromSearch) {
      //           if (
      //             res.data.clinics &&
      //             res.data.clinics[0] &&
      //             res.data.clinics[0].coordinates
      //           ) {
      //             res.data.clinics[0].coordinates.lat = parseFloat(
      //               res.data.clinics[0].coordinates.lat
      //             );
      //             res.data.clinics[0].coordinates.lng = parseFloat(
      //               res.data.clinics[0].coordinates.lng
      //             );
      //             this.center = res.data.clinics[0].coordinates;
      //           }
      //         }
      //         if (this.marketplace.length < 1) {
      //           this.noResultsFromSearch = true;
      //           const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      //             this.address
      //           )}&key=`;

      //           fetch(geocodeUrl)
      //             .then((response) => response.json())
      //             .then((data) => {
      //               if (data.status === "OK" && data.results.length > 0) {
      //                 console.log("truuu");
      //                 const { lat, lng } = data.results[0].geometry.location;
      //                 this.center = { lat, lng };
      //                 const middle = window.innerHeight / 2;
      //                 window.scrollTo({
      //                   top: middle,
      //                   behavior: "smooth",
      //                 });
      //               }
      //             })
      //             .catch((error) => {
      //               console.error("Geocoding error:", error);
      //             });
      //         } else {
      //           this.noResultsFromSearch = false;
      //           const middle = window.innerHeight / 2;
      //           window.scrollTo({
      //             top: middle,
      //             behavior: "smooth",
      //           });
      //         }
      //       }
      //     })
      //     .finally(() => {
      //       this.loader = false;
      //     });
      // } else {
      //   this.getMarketplace();
      //   this.getAllPlaces();
      // }
    },
    geocodeLocation(item, places) {
      if (item.entity === "location") {
        const address = item.address + item.city + item.state + item.zipCode;
        
        api()
            .post(`/member/app/market/locations/geocode`, { address })
            .then((res) => {
                if (res && res.data) {
                    const { lat, lng } = res.data.results[0].geometry.location;
                    item.latitude = lat;
                    item.longitude = lng;
                    const placeItem = { item, location: res.data.results[0].geometry.location };

                    places.push(placeItem);
                    if (this.fromSearch) {
                        this.center = places[0].location;
                    }
                }
            })
            .catch((error) => {
                console.error('Geocoding error:', error);
            });
      }
    },
    centerMapByAddress(address) {
      // console.log("centerMapByAdd", address);
      // this.$store.commit('setPage',1) // don't do other things, simply center map
      api()
        .post(`/member/app/market/locations/geocode`, { address })
        .then((res) => {
            if (res && res.data) {
                // this.center = data.results[0].geometry.location
                // console.log(data.results[0].geometry.location, address);
                this.$store.commit(
                "setMapCenter",
                res.data.results[0].geometry.location
                );
            } else {
                this.$store.commit("setMapCenter", this.userCenter);
            }
        })
        .catch((error) => {
            console.error('Geocoding error:', error);
        });
    },

    searchByEmployer() {},
    noResultsFoundReset() {
      // this.loader = false;
      this.$store.commit("setLoader", false);
      this.zipOrState = "";
      this.searchQuery = "";
      this.selectedCategories = [];
      this.selectedCredentials = [];
      this.selectedGenders = [];
      this.selectedGroupAndOrganizations = [];
      this.selectedServiceTypeCodes = [];
      this.selectedSpecialities = [];
      this.newPatients = false;
      this.address = null;
      this.$store.commit("setAddressSeached", null);

      this.getMarketplace();
    },
    showMoreCredentials() {
      this.showAllCredentials = true;
      this.slicedProviderCredentials = this.providerCredentials;
    },
    showLessCredentials() {
      this.showAllCredentials = false;
      this.slicedProviderCredentials = this.providerCredentials.slice(0, 3);
    },
    showMoreSpecialities() {
      this.showAllSpecialities = true;
      this.slicedSpecialities = this.specialities;
    },
    showLessSpecialities() {
      this.showAllSpecialities = false;
      this.slicedSpecialities = this.specialities.slice(0, 3);
    },
    showMoreGroups() {
      this.showAllGroups = true;
      // this.slicedGroupAndOrganizations = this.groupAndOrganizations;
      if (this.$route.query.company) {
        this.slicedGroupAndOrganizations = [
          this.companyFromUrl,
          ...this.groupAndOrganizations,
        ];
        // console.log("Filter: Group and Org, Show more WITH Company");
      } else {
        // console.log("Filter: Group and Org, Show more no Company");
        // console.log(this.filteredSlicedGroupAndOrganizations);
        // console.log(this.selectedGroupAndOrganizations);
        this.slicedGroupAndOrganizations = this.groupAndOrganizations;
      }
    },
    showLessGroups() {
      this.showAllGroups = false;
      if (this.$route.query.company) {
        this.slicedGroupAndOrganizations = [
          this.companyFromUrl,
          ...this.groupAndOrganizations.slice(0, 3),
        ];
      } else {
        this.slicedGroupAndOrganizations = this.groupAndOrganizations.slice(
          0,
          3
        );
      }
    },
    getEmployers() {
      api()
        .get("/member/app/potential/employers")
        .then((res) => {
          this.employers = res.data;
          this.employersCopy = res.data;
          this.allEmployers = res.data;
        });
    },
    getProviderCredentials() {
      api()
        .get("/market/credentials/provider")
        .then((res) => {
          this.providerCredentials = res.data;
          this.slicedProviderCredentials = res.data.slice(0, 3);
        });
    },
    getSpecialities() {
      api()
        .get("/market/specialities")
        .then((res) => {
          this.specialities = res.data;
          this.slicedSpecialities = res.data.slice(0, 3);
        });
    },
    getGroupAndOrganizations() {
      api()
        .get("/market/organization/company/name")
        .then((res) => {
          this.groupAndOrganizations = res.data;
          if (this.$route.query.company) {
            api()
              .post("/market/organization/from/alias", {
                company: this.$route.query.company,
              })
              .catch((err) => {
                if (err) {
                  // console.log(err);
                  // console.log("Custom Provider URL", err.response.data);
                  // this.$swal(err.response.data, "", "error");
                  // this.genError = true;
                  // this.genErrorMess = err.response?.data;
                  // this.genErrorMess = "No provider found";
                  this.$router.push('/marketplace');
                }
              })
              .then((response) => {
                if (response) {
                  this.companyFromUrl = response.data.companyName;
                  this.aliasUrl = response.data.alias;
                  this.selectedGroupAndOrganizations = [
                    response.data.companyName,
                  ];
                  const filteredGroupAndOrganizations =
                    this.groupAndOrganizations.filter(
                      (item) => item !== this.companyFromUrl
                    );
                  this.slicedGroupAndOrganizations = [
                    this.companyFromUrl,
                    ...filteredGroupAndOrganizations.slice(0, 2),
                  ];
                }
              });
          } else {
            this.slicedGroupAndOrganizations = this.groupAndOrganizations.slice(
              0,
              3
            );
          }
        });
    },
    searchFilters() {
      // this.pageNumber = 1
      // this.$store.commit('setMapCenter', this.userCenter)
      if (this.tab == "clinicians") this.pageNumber = 1; // FE pagination
      this.$store.commit("setPage", 1);
      this.didFilter = true;

        let newDistance;

        if(this.searchDistance) {
            // console.log("this.searchDistance: ", this.searchDistance);
            if (this.searchDistance == 1) newDistance = 10;
            else if (this.searchDistance == 2) newDistance = 15;
            else if (this.searchDistance == 3) newDistance = 20;
            else if (this.searchDistance == 4) newDistance = 25;
            else if (this.searchDistance == 5) newDistance = 30;
            else if (this.searchDistance == 6) newDistance = 35;
            else if (this.searchDistance == 7) newDistance = 40;
            else if (this.searchDistance == 8) newDistance = 45;
            else if (this.searchDistance == 9) newDistance = 50;
        }

      this.$store.commit("setMarketSearchFilters", {
        searchDistance: newDistance,
        serviceTypes: this.pediatrics == true ? ['BH']: [],
        selectedSpecialities: this.selectedSpecialities || [],
        selectedGenders: this.selectedSpecialities || [],
        selectedGroupAndOrganizations: this.selectedGroupAndOrganizations || [],
      });

      if(!this.selectedEmployerInfo) {
        // Require Employer
        this.drawer = false // Hide filter
        this.needEmployer = true
      }
      else this.searchResults();
    },
    convertFiltersToStringArray(filters) {
        return Object.entries(filters)
            .filter(([_, value]) => !(Array.isArray(value) && value.length === 0))
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return `${key}: ${value.join(', ')}`;
                } else {
                    return `${key}: ${value}`;
                }
            });
    },
    searchResults(searchBounds) {
      // this.didFilter = true;
      if (
        this.searchDistance == 0 &&
        this.searchByName == "" &&
        this.address == "" &&
        this.pediatrics == false &&
        this.selectedGenders.length === 0 &&
        this.selectedSpecialities.length === 0 &&
        this.selectedGroupAndOrganizations.length === 0 &&
        this.newPatients == false
        // this.selectedCategories.length === 0 &&
        // this.selectedCredentials.length === 0 &&
        // this.selectedServiceTypeCodes.length === 0 &&
      ) {
        console.log('1');
        this.didFilter = false;
        this.drawer = false;
        this.getMarketplace(); // T.N. if all search params are cleared, it's the same as getMarketplace
        return;
      }
      this.hasNextPage = false;
      let newDistance;
      if(this.searchDistance) {
        // console.log("this.searchDistance: ", this.searchDistance);
        if (this.searchDistance == 1) newDistance = 10;
        else if (this.searchDistance == 2) newDistance = 15;
        else if (this.searchDistance == 3) newDistance = 20;
        else if (this.searchDistance == 4) newDistance = 25;
        else if (this.searchDistance == 5) newDistance = 30;
        else if (this.searchDistance == 6) newDistance = 35;
        else if (this.searchDistance == 7) newDistance = 40;
        else if (this.searchDistance == 8) newDistance = 45;
        else if (this.searchDistance == 9) newDistance = 50;
      }

      const isZipCode = this.isZipCode(this.address)

      // console.log("newDistance: ", newDistance);
      let filters = {
        searchByName: this.searchByName,
        searchDistance: newDistance,
        serviceTypes: this.pediatrics == true ? ['BH']: [], // BH indicates pediatric service
        selectedSpecialities: this.selectedSpecialities,
        selectedGenders: this.selectedGenders,
        selectedGroupAndOrganizations: this.selectedGroupAndOrganizations,
        query: this.$route.query, 
        employer: this.empl?.id,
        userZipCode: this.userZipcode,
        // newPatients: this.newPatients, // Update the memberapp code to send newPatients only if toggled on
        // selectedCategories: this.selectedCategories,
        // selectedCredentials: this.selectedCredentials,
      };
      if (this.newPatients) { // Somehow, this will return both accepting and not accepting new patients
        // console.log("newPatients: ", this.newPatients);
        filters.newPatients = true;
      }

        const metadata = {
            searchByZip: this.extractZipCode(this.address),
            sessionId: `anon_${Date.now()}`,
            member: this.memberInfo.id ? this.memberInfo.id : null,
            address_searched: this.address,
            employer_name: this.empl?.companyName,
            distance_search: filters.searchDistance,
            email: this.memberInfo.email ? this.memberInfo.email : null,
            filters: this.convertFiltersToStringArray({
                searchDistance: filters.searchDistance,
                serviceTypes: filters.serviceTypes,
                selectedSpecialities: filters.selectedSpecialities,
                selectedGenders: filters.selectedGenders,
                selectedGroupAndOrganizations: filters.selectedGroupAndOrganizations,
            })
        }

        const queryParams = new URLSearchParams();

        if (filters.newPatients) {
            queryParams.append('newPatients', 'true');
        }

        if (filters.serviceTypes.length > 0) {
            queryParams.append('serviceTypes', filters.serviceTypes.toString());
        }

        if (filters.employer) {
            queryParams.append('employer', filters.employer);
        }

        if (isZipCode) {
            queryParams.append('zipCode', this.address);
        }

        if (this.userZipcode) {
            queryParams.append('userZipCode', this.userZipcode);
        }

        if (this.$route.query.company) {
            this.$store.dispatch("getAllPlaces", { alias: this.$route.query.company, query: queryParams.toString()});
        }
        else if (searchBounds && searchBounds == true) { // Search by Bounds
          // Include Map Bounds
          queryParams.append('nwLng', this.mapBounds.nwLng);
          queryParams.append('nwLat', this.mapBounds.nwLat);
          queryParams.append('seLng', this.mapBounds.seLng);
          queryParams.append('seLat', this.mapBounds.seLat);

          // Store query
          this.$store.dispatch("getAllPlaces", { searchByBounds: true, query: queryParams.toString()});

          // Update Marketplace
          this.$store.dispatch("getMarketplace", {
            query: {
                ...this.$route.query,
                userZipCode: this.userZipcode,
                nwLng: this.mapBounds.nwLng,
                nwLat: this.mapBounds.nwLat,
                seLng: this.mapBounds.seLng,
                seLat: this.mapBounds.seLat
            },
            metadata
          });
        }
        else {
          this.drawer = false;
          this.$store.dispatch("searchMarketplaceByFilters", { filters, metadata });
          this.$store.dispatch("getAllPlaces", { alias: null, query: queryParams.toString()});
        }
    
    },
    clearSearch(close) {
      this.$store.commit("setLoader", false);
      // this.selectedCategories = [];
      // this.selectedCredentials = [];
      // this.selectedGroupAndOrganizations = [];
      // this.selectedServiceTypeCodes = [];
      
      

      if(close == "clearFilter"){

        // If any of these has value, then remove the value
        this.selectedGenders = [];
        this.selectedSpecialities = [];
        this.selectedGroupAndOrganizations = [];
        this.pediatrics = false,
        this.newPatients = false;

        this.searchDistance = 50; // Default
        this.$store.commit("setMarketSearchFilters", { searchDistance: 50 });

        this.searchByName = "";
        this.address = "";
        this.$store.commit("setAddressSeached", null);

        this.didFilter = false;

        // Reload the page
        this.searchResults();
      }
      else if(close == "closeOnly") {
        // Just close drawer, nothing more
        if(this.didFilter == false) {
          this.selectedGenders = [];
          this.selectedSpecialities = [];
          this.selectedGroupAndOrganizations = [];
          this.pediatrics = false,
          this.newPatients = false;
        }
      }
      console.log(3);
      this.drawer = false;
      this.pageNumber = 1;
      

      // Display chatbot
      document.getElementById("zsiq_float").style.display = 'block';
    },
  },
};
</script>

<style scoped lang="scss">
.drawer-header {
  background-color: #194680;
  padding: 15px;
  color: white;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: normal;
    text-align: center;
    width: 100%;
    font-size: 20px;
  }
}
.show-on-mobile {
  display: none !important;
}
.mask .theme--light.v-list-item .v-list-item__mask {
  background: red !important; /* Change to your desired mask color */
}
.map-height {
  // height: 70vh;
  transition: 0.3s;
  height: calc(100vh - 70px);
}
.blue-bg {
  background-color: #102a4c;
}
.text-ellipsis {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.marketplace-item-content-name p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin-top: 0;
  color: #112950;
}
.marketplace-item-content-address {
  display: flex;
  align-items: flex-start;
}
.marketplace-item-content-address p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.035px;
  display: flex;
}
.marketplace-item-content-address img {
  width: 16px;
  height: 16px;
}
.marketplace-item-content-category {
  display: flex;
  align-items: flex-start;
}
.marketplace-item-content-category p {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.035px;
  display: flex;
  color: gray;
}
.marketplace-item-content-category img {
  width: 16px;
  height: 16px;
}
.marketplace-item-actions {
  display: flex;
  justify-content: space-between;
}
.marketplace-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.marketplace-item-actions {
  margin-top: 3px;
}
.card-radius {
  border-radius: 8px !important;
}
.marketplace-item-header {
  height: 35px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.marketplace-item-logo {
  position: absolute;
  height: 45px;
  width: 80px;
  background-color: white;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  left: 8px;
  top: 10px;
  background-size: contain;
  background-position: center;
}
.v-input__prepend-inner i {
  font-size: 16px !important; /* Set the size to 16px */
}
.font-secondary {
  color: #366cb3 !important;
  box-shadow: none;
}
div:where(.swal2-container).swal2-top-end > .swal2-popup,
div:where(.swal2-container).swal2-top-right > .swal2-popup {
  display: flex !important;
  font-size: 10px !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 35px !important;
}
.v-input--selection-controls {
  // margin-top: 0px !important;
  padding-top: 0px !important;
  margin-bottom: 8px;
}
.active {
  color: #333333 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #333333;
}
.active:hover {
  color: #333333 !important;
}
.display-grid {
  display: grid !important;
}
.apaly-bg {
  background-color: #194680;
}
.scrollable-drawer {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scrollable-creds {
  overflow-y: scroll;
  height: 30vh;
}
.marketplace-header {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  border-bottom: 5px solid #194680;
  &--logo {
    img {
      width: 100px;
    }
  }
  &--text {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    &-title {
      font-size: 36px;
    }
    &-subtitle {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  &--filters {
    display: flex;
    div {
      margin-right: 10px;
    }
  }
}
.marketplace-content {
  padding: 30px;
}
.drawer-content {
  height: 80vh;
  overflow: scroll;
}

@media screen and (max-width: 700px) {
  .text-mobile-p {
    padding-left: 4px !important;
  }
  .field-font-size {
    font-size: 12px !important;
  }
  .emp-style {
    padding-left: 0px;
    margin-top: 10px;
  }
  .show-on-mobile {
    display: flex !important;
  }
  .new-logo-display {
    display: none;
  }
  .map-height {
    transition: 0.3s;
    height: 100vh; // Show map by default for now
        // By Default, MAP is hidden
    // height: 0px;
  }
  .showMap {
    // Add this class to show map
    height: calc(100vh - 349px);
  }
  .map-position {
    flex-direction: column-reverse;
  }
  .marketplace-content {
    padding: 10px;
  }
  .search-inputs {
    margin-left: 0px;
  }
    .newMarket_header {
        margin-top: 55px;
    }
    .header_marketpalce_container {
        height: 240px;
        background-size: 180px;
    }
    .marketplace_view_switch {
        font-size: 14px;
        display: block;
    }

    .switch_to_list {
        font-size: 14px;
        display: block;
        font-size: 14px;
        position: absolute;
        top: 270px;
        left: 10px;
        z-index: 1;
    }

    .marketplace_view_switch_button {
        border: 1px solid #1E7BF4 !important;
        color: #1E7BF4;
        border-radius: 8px;
        padding: 8px 15px;
        height: auto;
    }

    .marketplace_view_switch_button_map {
        border: 1px solid #1E7BF4 !important;
        color: #1E7BF4;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 8px 15px;
        height: auto;
    }

  .marketplace-header {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    border-bottom: 5px solid #194680;
    flex-direction: column;
    &--logo {
      img {
        width: 100px;
      }
    }
    &--text {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      &-title {
        font-size: 24px;
      }
      &-subtitle {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    &--filters {
      display: flex;
      flex-direction: column;
      div {
        margin-right: 10px;
        margin-bottom: 20px;
      }
    }
  }
  .font-36 {
    font-size: 24px;
  }
  .font-20 {
    font-size: 14px;
  }
  .img-mobile {
    width: 75px;
    height: 100px;
  }
  .drawer-mobile-height {
    height: 70vh !important;
  }
}
.text-ellipsis {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.box-hover:hover {
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.16);
}
::v-deep .checkbox-label-size .v-label {
  font-size: 14px;
  color: #333333 !important;
}
.v-application .elevation-3 {
  box-shadow: none !important;
}

</style>
